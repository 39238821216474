import React, { useEffect, useState } from "react";
import axiosInstance from "../../hooks/axiosInstance";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuthContext";
import LocationInput from "../FormComponents/LocationInput";
import Loader from "../Utilities/Loader/Loader";

const EditProfile = ({ personalDetail }) => {
  const { fetchUserDetails } = useAuth();
  const [isDeliverable, setIsDeliverable] = useState(true)
  const [formData, setFormData] = useState({
    email: "",
    company_name: "",
    first_name: "",
    last_name: "",
    phone: "",
    address: "",
    coordinates: "",
  });

  const [validationError, setValidationError] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const checkDeliverability = (status) => {
    setIsDeliverable(status)
  }

  const setInputAddress = (address) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      address: address,
    }));
  };

  const setInputCoordinates = (coordinates) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      coordinates: coordinates,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setValidationError({});
    formData.email = personalDetail?.email;
    axiosInstance
      .post("/user/profile", formData)
      .then((res) => {
        toast.success(res.data.message);
        fetchUserDetails();
      })
      .catch((err) => {
        toast.error("An error occured. Please, try again");
        if (err.response.status == 422) {
          setValidationError(err.response.data.errors);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setFormData({
      first_name: personalDetail?.first_name,
      last_name: personalDetail?.last_name,
      address: personalDetail?.address,
      phone: personalDetail?.phone,
      email: personalDetail?.email,
      company_name: personalDetail?.company_name,
    });
  }, [personalDetail]);
  return (
    <>
      {/* <!-- Profile Edit Form --> */}
      <form onSubmit={handleSubmit}>
        {/* <div className="row mb-3">
          <label
            htmlFor="profileImage"
            className="col-md-4 col-lg-3 col-form-label"
          >
            Profile Image
          </label>
          <div className="col-md-8 col-lg-9">
            <img src="assets/img/profile-img.jpg" alt="Profile" />
            <div className="pt-2">
              <a
                href="#"
                className="btn btn-primary btn-sm"
                title="Upload new profile image"
              >
                <i className="bi bi-upload"></i>
              </a>
              <a
                href="#"
                className="btn btn-danger btn-sm"
                title="Remove my profile image"
              >
                <i className="bi bi-trash"></i>
              </a>
            </div>
          </div>
        </div> */}

        <div className="row mb-3">
          <label htmlFor="firstName" className="col-md-4 col-lg-3 col-form-label">
            First Name
          </label>
          <div className="col-md-8 col-lg-9">
            <input
              type="text"
              className="form-control"
              id="firstName"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
            />
            {validationError.first_name && <small className="text-danger my-1"> {validationError.first_name[0]} </small>}
          </div>
        </div>

        <div className="row mb-3">
          <label htmlFor="lastName" className="col-md-4 col-lg-3 col-form-label">
            Last Name
          </label>
          <div className="col-md-8 col-lg-9">
            <input
              type="text"
              className="form-control"
              id="lastName"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
            />
            {validationError.last_name && <small className="text-danger my-1"> {validationError.last_name[0]} </small>}
          </div>
        </div>

        <div className="row mb-3">
          <label htmlFor="Email" className="col-md-4 col-lg-3 col-form-label">
            Email
          </label>
          <div className="col-md-8 col-lg-9">
            <input
              name="email"
              type="email"
              className="form-control"
              id="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {validationError.email && <small className="text-danger my-1"> {validationError.email[0]} </small>}
          </div>
        </div>

        {personalDetail?.user_type == "supplier" && (
          <div className="row mb-3">
            <label htmlFor="company" className="col-md-4 col-lg-3 col-form-label">
              Company Name
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                name="company_name"
                type="text"
                className="form-control"
                id="company"
                value={formData.company_name}
                onChange={handleChange}
              />
              {validationError.company_name && <small className="text-danger my-1"> {validationError.company_name[0]} </small>}
            </div>
          </div>
        )}

        <div className="row mb-3">
          <label htmlFor="Address" className="col-md-4 col-lg-3 col-form-label">
            Address
          </label>
          <div className="col-md-8 col-lg-9">
            <LocationInput
              formWrapClass="form-wrap"
              name="address"
              inputClass="form-control"
              handleChange={handleChange}
              formValue={formData.address}
              setInputAddress={setInputAddress}
              setInputCoordinates={setInputCoordinates}
              checkDeliverability={checkDeliverability}
              locationClass="d-flex gap-3 special"
              required
            />
            {validationError.address && <small className="text-danger my-1"> {validationError.address[0]} </small>}
          </div>
        </div>

        <div className="row mb-3">
          <label htmlFor="Phone" className="col-md-4 col-lg-3 col-form-label">
            Phone
          </label>
          <div className="col-md-8 col-lg-9">
            <input
              name="phone"
              type="text"
              className="form-control"
              id="Phone"
              value={formData.phone}
              onChange={handleChange}
            />
            {validationError.phone && <small className="text-danger my-1"> {validationError.phone[0]} </small>}
          </div>
        </div>

        <div className="text-right">
          <button type="submit" className="btn btn-primary">
            Save Changes
          </button>
        </div>
        {loading && <Loader />}
      </form>
      {/* <!-- End Profile Edit Form --> */}
    </>
  );
};

export default EditProfile;
