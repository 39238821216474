import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

export default function Footer() {
    const [year, setYear] = useState('')
    useEffect(() => {
        const getYear = new Date().getFullYear()
        setYear(getYear)
    }, [year])

    const reload = () => {
        window.scrollTo(0, 0);
    }

  return (
    <div>
      {/* <!-- Page Footer--> */}
      <footer class="footers">
          <div class="footers-one">
              <div class="container">
                  <div class="row rowss justify-content-lg-between">
                      <div class="col-sm-7 col-lg-3 col-xl-2">
                          <a href="/index.html">
                              <img src="/assets/images/logo-111x33.png" alt="" class="img-fluid" />
                          </a>
                          <p style={{maxWidth: "175px"}} class="my-4">
                            We provide the best gas delivery for you all over the country.
                          </p>
                          <button onClick={reload} class="btn btn-sm btn btn-outline-secondary btn-lg text-uppercase">Order
                              now</button>
                      </div>
                      <div class="col-sm-5 col-lg-3 col-xl-2 about">
                          <h5>ABOUT</h5>
                          <ul>
                              <li>
                                  <a href="">About Us</a>
                              </li>
                              <li>
                                  <a href="">Careers</a>
                              </li>
                              <li>
                                  <a href="">Press and media</a>
                              </li>
                          </ul>
                      </div>
                      <div class="col-sm-7 col-lg-5 col-xl-3 quick">
                          <h5>QUICK LINKS</h5>
                          <ul class="footer_services">
                              <li class="signup"><a href="">Sign Up</a></li>
                              <li><a href="">Services</a></li>
                              <li><a href="/privacy.pdf" target="_blank">Privacy Policy</a></li>
                              <li><a href="/terms.pdf" target='_blank'>Terms & Condition</a></li>
                              <li><a href="">Pricing</a></li>
                              <li><a href="">Online Help</a></li>
                              <li><a href="">FAQ</a></li>

                          </ul>
                      </div>
                      <div class="col-sm-5 col-lg-9 col-xl-2">
                          <h5 class="text-uppercase">Contacts</h5>
                          <div class="row row-20 row-sm-35">
                              <div class="col-6 col-sm-12 col-lg-8 col-xl-12">
                                  <div class="row my-4">
                                      <div class="col-lg-6 col-xl-12 infos">
                                          <a href="" class="info">info@neongas.ng</a>
                                      </div>
                                      <div class="col-lg-6 col-xl-12 infos">
                                          4A, Atlantic Beach Estate, Victoria Island, Lagos
                                      </div>
                                      <div class="col-lg-6 col-xl-12">
                                        +234 90 9000 0130
                                      </div>
                                  </div>
                              </div>
                              <div class="col-6 col-sm-12 col-lg-4 col-xl-12 text-end text-sm-start">
                                  <div class="group">
                                      <a href="#"><i class="bi bi-twitter"
                                              style={{fontSize: "24px", color: "#63c9eb"}}></i></a>
                                      <a href="#"><i class="bi bi-facebook"
                                              style={{fontSize: "24px", color: "#2b4796"}}></i></a>
                                      <a href="#"><i class="bi bi-instagram"
                                              style={{fontSize: "24px", color: "#ff6f80"}}></i></a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

              </div>
          </div>
          <div class="text-center py-4 copyright">
              <div class="container">
                  <p class="">
                      <span>©&nbsp; </span>
                      <span>{year} </span>
                      <span class="text-capitalize">All rights reserved </span>
                  </p>
              </div>
          </div>
      </footer>
    </div>
  )
}
