import axiosInstance from "../hooks/axiosInstance"

const fetchNearbySuppliers = async(userLocation) => {
    const response = await axiosInstance.post(`/user/nearby_suppliers`, userLocation)

    return response.data
}

const nearbySupplierService = {
    fetchNearbySuppliers,
}

export default nearbySupplierService