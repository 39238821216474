import React, { useEffect, useState } from "react";
import "./Transaction.css";
import { Link, NavLink } from "react-router-dom";
import { Pagination, Stack, Typography } from "@mui/material";
import ViewTransaction from "../../../components/Modal/ViewUserTransaction";
import { Badge } from "react-bootstrap";
import axiosInstance from "../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import ViewHistory from "../../../components/Modal/ViewHistory";

export default function Transaction() {
  const [transactions, setTransactions] = useState([]);
  const [currentTransaction, setCurrentTransaction] = useState(null)
  const [filter, setFilter] = useState("");
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [loading, setLoading] = useState(false);

  //  state for pagination
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);

  //  pagination function
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleShow = async(history) => {
    await setCurrentTransaction(history)
    setShow(true)
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleRefresh = () => {
    setRefreshKey((prevRefreshKey) => prevRefreshKey + 1);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  // const handleDeleteOpen = async (data) => {
  //   await setCurrentVehicle(data);
  //   await setDeleteOpen(true);
  // };

  // const handleDeleteClose = () => {
  //   setDeleteOpen(false);
  // };

  const fetchOrderTransactions = () => {
    setLoading(true);
    axiosInstance
      .get(
        `/admin/order/transactions/all?page=${page}&limit=${perPage}&filter=${filter}`
      )
      .then((response) => {
        setTransactions(response.data.data.data);
        const pageCount = Math.ceil(
          response.data.data.total / response.data.data.per_page
        );
        setPageCount(pageCount);
      })
      .catch((err) => {
        toast.error("An error occured. Please, try again");
      })
      .finally(() => setLoading(false));
  };

  const checkStatus = async(transRef) => {
    setLoading(true)
    axiosInstance.post('/user/order/verify-payment', {transRef})
            .then(res =>  {
              toast.success("Payment verified successfully")
              fetchOrderTransactions()
            })
            .catch(err => console.log(err.response))
            .finally(() => setLoading(false))
  }

  // const handleDelete = () => {
  //   setLoading(true);
  //   axiosInstance
  //     .delete(
  //       `/user/supplier/vehicle/${currentVehicle?.id}`
  //     )
  //     .then((response) => {
  //       toast.success("Vehicle deleted successfully")
  //       setDeleteOpen(false)
  //       handleRefresh()
  //     })
  //     .catch((err) => {
  //       toast.error("An error occured. Please, try again");
  //     })
  //     .finally(() => setLoading(false));
  // };

  const filterTransactions = () => {
    fetchOrderTransactions();
  };

  useEffect(() => {
    fetchOrderTransactions();
  }, [page, perPage, refreshKey]);

  return (
    <>
      <div className="pagetitle">
        <h1>Transactions</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/app/user/dashboard">Dashboard</NavLink>
            </li>
            <li className="breadcrumb-item">
              <NavLink to="/app/user/order">Order</NavLink>
            </li>
            <li className="breadcrumb-item active">Transactions</li>
          </ol>
        </nav>
      </div>

      <section className="section transaction">
        <div className="row">
          <div className="col-lg-12 col-md-12 order-2 order-sm-1 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div>
                    <h4 className="card-title d-flex align-items-center">
                      Transaction History
                    </h4>
                  </div>
                </div>

                {/* Filter input */}
                <div className="row mb-3">
                  <div className="col-lg-3">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Transaction Reference..."
                        value={filter}
                        onChange={handleFilterChange}
                      />
                      <button
                        onClick={filterTransactions}
                        className="btn btn-primary"
                        type="submit"
                      >
                        Go
                      </button>
                    </div>
                  </div>
                </div>

                {transactions && transactions.length > 0 ? (
                  <>
                    {/* <!-- Start Table --> */}
                    <div className="scroll-area-lg">
                      <div className="table-responsive-sm">
                        <table className="table table-striped table-borderless table-hover ">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Transaction Ref.</th>
                              <th scope="col">Description</th>
                              <th scope="col">Total Amount (₦)</th>
                              <th scope="col">Payment Mode</th>
                              <th scope="col">Transaction Date</th>
                              <th scope="col">Status</th>
                              <th scope="col" width="5%"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {transactions.map((transaction, i) => (
                              <tr key={transaction.id}>
                                <th scope="row">{i + 1}</th>
                                <td>{transaction?.transaction_reference}</td>
                                <td>Payment for {transaction?.order?.quantity}kg refil</td>
                                <td>{Number(transaction?.amount).toLocaleString()}</td>
                                <td>
                                  <span className={`${transaction?.payment_type == 'card' ? 'bg-dark': 'bg-primary'} 
                                    badge text-uppercase`}>
                                    {transaction?.payment_type}
                                  </span>
                                </td>
                                <td>{transaction.created_at}</td>
                                <td
                                >
                                  {transaction.status === "pending" ? (
                                    <span className="badge bg-warning text-capitalize">
                                      {transaction.status}
                                    </span>
                                  ) : transaction.status === "approved" ? (
                                    <span className="badge bg-success text-capitalize">
                                      {transaction.status}
                                    </span>
                                  ) : (
                                    <span className="badge bg-danger text-capitalize">
                                      {transaction.status}
                                    </span>
                                  )}
                                </td>
                                <td className="dropdown">
                                  <Link
                                    className="dropdown-toggle menu"
                                    id={`actionDropdown_${transaction.id}`}
                                    href="#"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="mdi mdi-apps"></i>
                                  </Link>
                                  <div
                                    className="dropdown-menu dropdown-menu-right preview-list"
                                    aria-labelledby={`actionDropdown_${transaction.id}`}
                                  >
                                    <Link
                                      className="dropdown-item custom-drop"
                                      onClick={() => handleShow(transaction)}
                                    >
                                      <i className="mdi mdi-pencil mr-2 text-success"></i>{" "}
                                      <span>View Order</span>
                                    </Link>
                                    {transaction.status != 'approved' && transaction?.payment_type == 'card'  && <Link
                                      className="dropdown-item custom-drop"
                                      onClick={() => checkStatus(transaction.transaction_reference)}
                                    >
                                      <i className="mdi mdi-tag-check-outline mr-2 text-success"></i>{" "}
                                      <span>Requery</span>
                                    </Link>}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end mt-2">
                      <div className="d-flex">
                        per page
                        <select
                          className="ml-1"
                          name="perPage"
                          onChange={(e) => setPerPage(e.target.value)}
                          value={perPage}
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                        </select>
                      </div>
                      <Pagination
                        onChange={handlePageChange}
                        count={pageCount}
                        color="primary"
                        shape="rounded"
                        size="small"
                        page={page}
                      />
                    </div>
                    {/* <!-- End Table --> */}
                  </>
                ) : (
                  <div className="alert alert-info mt-4">No record found</div>
                )}
              </div>
            </div>
          </div>
        </div>

        <ViewHistory
          show={show}
          closeModal={handleClose}
          currentHistory={currentTransaction?.order}
          refreshPage={handleRefresh}
        />
      </section>
    </>
  );
}
