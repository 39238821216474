import React, { useEffect, useState } from "react";
import axiosInstance from "../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import { Link, NavLink } from "react-router-dom";
import Loader from "../../../components/Utilities/Loader/Loader";

export default function AdminDashboard() {
  const [orderHistory, setOrderHistory] = useState([]);
  const [orderAnalytics, setOrderAnalytics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);

  //  state for pagination
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const fetchOrderHistory = () => {
    setLoading(true);
    axiosInstance
      .get(`/admin/order/all?page=${page}&limit=${perPage}`)
      .then((response) => {
        setOrderHistory(response.data.data.data);
      })
      .catch((err) => {
        toast.error("An error occured. Please, try again");
      })
      .finally(() => setLoading(false));
  };

  const fetchOrderAnalytics = () => {
    setLoading(true);
    axiosInstance
      .get(`/admin/dashboard/analytics`)
      .then((response) => {
        setOrderAnalytics(response.data.analysis);
      })
      .catch((err) => {
        toast.error("An error occured. Please, try again");
      })
      .finally(() => setLoading(false));
  };

  const checkStatus = async(transRef) => {
    setLoading(true)
    axiosInstance.post('/user/order/verify-payment', {transRef})
            .then(res =>  {
              toast.success("Payment verified successfully")
              fetchOrderTransactions()
            })
            .catch(err => console.log(err.response))
            .finally(() => setLoading(false))
  }

  const fetchOrderTransactions = () => {
    setLoading(true);
    axiosInstance
      .get(
        `/admin/order/transactions/all?page=${page}&limit=${perPage}`
      )
      .then((response) => {
        setTransactions(response.data.data.data);
      })
      .catch((err) => {
        toast.error("An error occured. Please, try again");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchOrderHistory();
    fetchOrderAnalytics();
    fetchOrderTransactions()
  }, [page, perPage]);

  return (
    <>
      <div className="pagetitle">
        <h1>Dashboard</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="index.html">Home</a>
            </li>
            <li className="breadcrumb-item">Dashboard</li>
          </ol>
        </nav>
      </div>
      {/* <!-- End Page Title --> */}

      <section className="section dashboard">
        <div className="row">
          {/* <!-- Left side columns --> */}
          <div className="col-lg-12">
            <div className="row">
              {/* <!-- Sales Card --> */}
              <div className="col-xxl-3 col-md-6">
                <div className="card info-card sales-card">
                  <div className="card-body">
                    <h5 className="card-title">Vehices</h5>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-truck"></i>
                      </div>
                      <div className="ps-3">
                        <h6>
                          {Number(
                            orderAnalytics?.vehicle_count
                          ).toLocaleString()}
                        </h6>
                        {/* <span className="text-success small pt-1 fw-bold">
                          ₦
                          {Number(
                            orderAnalytics?.total_amount
                          ).toLocaleString()}
                        </span> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Sales Card --> */}

              {/* <!-- Sales Card --> */}
              <div className="col-xxl-3 col-md-6">
                <div className="card info-card sales-card">
                  <div className="card-body">
                    <h5 className="card-title">Orders</h5>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-cart"></i>
                      </div>
                      <div className="ps-3">
                        <h6>
                          {Number(
                            orderAnalytics?.total_orders
                          ).toLocaleString()}
                        </h6>
                        <span className="text-success small pt-1 fw-bold">
                          ₦
                          {Number(
                            orderAnalytics?.total_amount
                          ).toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Sales Card --> */}

              {/* <!-- Revenue Card --> */}
              <div className="col-xxl-3 col-md-6">
                <div className="card info-card revenue-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      Completed <span>orders</span>
                    </h5>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-cart"></i>
                      </div>
                      <div className="ps-3">
                        <h6>
                          {Number(
                            orderAnalytics?.total_completed
                          ).toLocaleString()}
                        </h6>
                        <span className="text-success small pt-1 fw-bold">
                          ₦
                          {Number(
                            orderAnalytics?.amount_completed
                          ).toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Revenue Card --> */}

              {/* <!-- Customers Card --> */}
              <div className="col-xxl-3 col-xl-12">
                <div className="card info-card customers-card">
                  <div className="card-body">
                    <h5 className="card-title text-warning">
                      Pending <span>orders</span>
                    </h5>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-cart"></i>
                      </div>
                      <div className="ps-3">
                        <h6>
                          {Number(
                            orderAnalytics?.total_pending
                          ).toLocaleString()}
                        </h6>
                        <span className="text-warning small pt-1 fw-bold">
                          ₦
                          {Number(
                            orderAnalytics?.amount_pending
                          ).toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Customers Card --> */}

              {/* <!-- Customers Card --> */}
              <div className="col-xxl-3 col-xl-12">
                <div className="card info-card cancel-card">
                  <div className="card-body">
                    <h5 className="card-title text-danger">
                      Cancelled <span>orders</span>
                    </h5>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-cart"></i>
                      </div>
                      <div className="ps-3">
                        <h6>
                          {Number(
                            orderAnalytics?.total_cancelled
                          ).toLocaleString()}
                        </h6>
                        <span className="text-danger small pt-1 fw-bold">
                          ₦
                          {Number(
                            orderAnalytics?.amount_cancelled
                          ).toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Customers Card --> */}

              {/* <!-- Customers Card --> */}
              <div className="col-xxl-3 col-xl-12">
                <div className="card info-card card-payment-card">
                  <div className="card-body">
                    <h5 className="card-title text-info">
                      Cash <span>payments</span>
                    </h5>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-cash"></i>
                      </div>
                      <div className="ps-3">
                        <h6>
                          {Number(
                            orderAnalytics?.total_cash_payments
                          ).toLocaleString()}
                        </h6>
                        <span className="text-info small pt-1 fw-bold">
                          ₦
                          {Number(
                            orderAnalytics?.total_cash_amount
                          ).toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Customers Card --> */}

              {/* <!-- Customers Card --> */}
              <div className="col-xxl-3 col-xl-12">
                <div className="card info-card cancel-card">
                  <div className="card-body">
                    <h5 className="card-title text-primary">
                      Card <span>payments</span>
                    </h5>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-credit-card-2-front"></i>
                      </div>
                      <div className="ps-3">
                        <h6>
                          {Number(
                            orderAnalytics?.total_card_payments
                          ).toLocaleString()}
                        </h6>
                        <span className="text-primary small pt-1 fw-bold">
                          ₦
                          {Number(
                            orderAnalytics?.total_card_amount
                          ).toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Customers Card --> */}

              {/* <!-- Customers Card --> */}
              <div className="col-xxl-3 col-xl-12">
                <div className="card info-card cancel-card">
                  <div className="card-body">
                    <h5 className="card-title text-primary">
                      Delivery <span>payments</span>
                    </h5>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-basket"></i>
                      </div>
                      <div className="ps-3">
                        <h6>
                          {Number(
                            orderAnalytics?.total_completed
                          ).toLocaleString()}
                        </h6>
                        <span className="text-primary small pt-1 fw-bold">
                          ₦
                          {Number(
                            orderAnalytics?.delivery_amount
                          ).toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Customers Card --> */}

              {/* <!-- Revenue Card --> */}
              <div className="col-xxl-3 col-md-6">
                <div className="card info-card revenue-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      Users
                    </h5>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-people"></i>
                      </div>
                      <div className="ps-3">
                        <h6>
                          {Number(
                            orderAnalytics?.total_users
                          ).toLocaleString()}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Revenue Card --> */}

              {/* <!-- Customers Card --> */}
              <div className="col-xxl-3 col-xl-12">
                <div className="card info-card card-payment-card">
                  <div className="card-body">
                    <h5 className="card-title text-info">
                      Customers
                    </h5>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-people"></i>
                      </div>
                      <div className="ps-3">
                        <h6>
                          {Number(
                            orderAnalytics?.total_customer
                          ).toLocaleString()}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Customers Card --> */}

              {/* <!-- Customers Card --> */}
              <div className="col-xxl-3 col-xl-12">
                <div className="card info-card cancel-card">
                  <div className="card-body">
                    <h5 className="card-title text-primary">
                      Suppliers
                    </h5>

                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-people"></i>
                      </div>
                      <div className="ps-3">
                        <h6>
                          {Number(
                            orderAnalytics?.total_supplier
                          ).toLocaleString()}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Customers Card --> */}
            </div>
          </div>
          {/* <!-- End Left side columns --> */}

          {/* <!-- Recent Sales --> */}
          <div className="col-xxl-8">
            <div className="card recent-sales overflow-auto">
              <div className="filter">
                <div className="icon">
                  <NavLink to="/app/admin/history">
                    <b>VIEW ALL</b>
                  </NavLink>
                </div>
              </div>

              <div className="card-body">
                <h5 className="card-title">Recent Orders</h5>

                {orderHistory && orderHistory.length > 0 ? (
                  <>
                    {/* <!-- Start Table --> */}
                    <div className="scroll-area-lg">
                      <div className="table-responsive-sm">
                        <table className="table table-striped table-borderless table-hover ">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Order No.</th>
                              <th width="15%" scope="col">Customer</th>
                              <th width="15%" scope="col">Supplier</th>
                              <th scope="col">Quantity (kg)</th>
                              <th scope="col">Amount (₦)</th>
                              {/* <th scope="col">Delivery (₦)</th> */}
                              <th scope="col">Date</th>
                              <th scope="col">Priority</th>
                              <th scope="col">Status</th>
                              {/* <th scope="col">Payment</th> */}
                              <th scope="col" width="5%"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderHistory.map((history, i) => (
                              <tr key={history.id}>
                                <th scope="row">{i + 1}</th>
                                <td>{history.order_number}</td>
                                <td>
                                  <p className="mb-0" style={{fontWeight: '600'}}>
                                    {history?.customer?.last_name} {history?.customer?.first_name}
                                  </p>
                                  <p className="mb-0" style={{fontSize: '13px'}}>
                                    {history?.customer?.phone}
                                  </p>
                                </td>
                                <td>
                                  <p className="mb-0" style={{fontWeight: '600'}}>
                                    {history?.supplier?.last_name} {history?.supplier?.first_name}
                                  </p>
                                  <p className="mb-0" style={{fontSize: '13px'}}>
                                    {history?.supplier?.phone}
                                  </p>
                                </td>
                                <td>{history.quantity}</td>
                                <td>
                                  {Number(
                                    history.order_amount
                                  ).toLocaleString()}
                                </td>
                                <td>{history.created_at}</td>
                                {/* <td>
                                  {history.delivery_price
                                    ? Number(
                                        history.delivery_price
                                      ).toLocaleString()
                                    : "-"}
                                </td> */}
                                <td>
                                  {history.priority === "high" ? (
                                    <span className="badge bg-success text-uppercase">
                                      {history.priority}
                                    </span>
                                  ) : history.priority === "medium" ? (
                                    <span className="badge bg-info text-uppercase">
                                      {history.priority}
                                    </span>
                                  ) : (
                                    <span className="badge bg-danger text-uppercase">
                                      {history.priority}
                                    </span>
                                  )}
                                </td>

                                <td>
                                  {history.status === "delivered" ? (
                                    <span className="badge bg-success text-capitalize">
                                      {history.status}
                                    </span>
                                  ) : history.status === "pending" ? (
                                    <span className="badge bg-warning text-capitalize">
                                      {history.status}
                                    </span>
                                  ) : (
                                    <span className=" badge bg-danger text-capitalize">
                                      {history.status}
                                    </span>
                                  )}
                                </td>

                                {/* <td>
                                  {history.payment_status === "approved" ? (
                                    <span className="badge bg-success text-capitalize">
                                      {history.payment_status}
                                    </span>
                                  ) : history.payment_status === "pending" ? (
                                    <span className="badge bg-warning text-capitalize">
                                      {history.payment_status}
                                    </span>
                                  ) : (
                                    <span className="badge bg-danger text-capitalize">
                                      {history.payment_status == "approved"
                                        ? "Paid"
                                        : history.payment_status}
                                    </span>
                                  )}
                                </td> */}

                                <td className="dropdown">
                                  <Link
                                    className="dropdown-toggle menu"
                                    id={`actionDropdown_${history.id}`}
                                    href="#"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="mdi mdi-apps"></i>
                                  </Link>
                                  <div
                                    className="dropdown-menu dropdown-menu-right preview-list"
                                    aria-labelledby={`actionDropdown_${history.id}`}
                                  >
                                    {/* {history.status ==  'delivered' && <Link
                                      className="dropdown-item custom-drop"
                                      onClick={() => handleShow(history)}
                                    >
                                      <i className="mdi mdi-pencil mr-2 text-success"></i>{" "}
                                      <span>View</span>
                                    </Link>} */}
                                    {/* {history.status == "pending" && (
                                      <Link
                                        className="dropdown-item custom-drop"
                                        to={`/app/user/order/details/${history.id}`}
                                      >
                                        <i className="mdi mdi-pencil mr-2 text-success"></i>{" "}
                                        <span>Continue Order</span>
                                      </Link>
                                    )} */}
                                    <Link className="dropdown-item custom-drop">
                                      <i className="mdi mdi-pencil mr-2 text-success"></i>{" "}
                                      <span>Print</span>
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* <!-- End Table --> */}
                  </>
                ) : (
                  <div className="alert alert-info mt-4">No record found</div>
                )}
              </div>
            </div>
          </div>
          {/* <!-- End Recent Sales --> */}

          {/* <!-- Recent Transaction --> */}
          <div className="col-xxl-4">
            <div className="card recent-sales overflow-auto">
              <div className="filter">
                <div className="icon">
                  <NavLink to="/app/admin/transaction">
                    <b>VIEW ALL</b>
                  </NavLink>
                </div>
              </div>

              <div className="card-body">
                <h5 className="card-title">Transactions</h5>

                {transactions && transactions.length > 0 ? (
                  <>
                    {/* <!-- Start Table --> */}
                    <div className="scroll-area-lg">
                      <div className="table-responsive">
                        <table className="table table-responsive-sm table-striped table-borderless table-hover" style={{width: '1000px'}}>
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Transaction Ref.</th>
                              <th scope="col">Description</th>
                              <th scope="col">Total Amount (₦)</th>
                              <th scope="col">Payment Mode</th>
                              <th scope="col">Transaction Date</th>
                              <th scope="col">Status</th>
                              <th scope="col" width="5%"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {transactions.map((transaction, i) => (
                              <tr key={transaction.id}>
                                <th scope="row">{i + 1}</th>
                                <td>{transaction?.transaction_reference}</td>
                                <td>Payment for {transaction?.order?.quantity}kg refil</td>
                                <td>{Number(transaction?.amount).toLocaleString()}</td>
                                <td>
                                  <span className={`${transaction?.payment_type == 'card' ? 'bg-dark': 'bg-primary'} 
                                    badge text-uppercase`}>
                                    {transaction?.payment_type}
                                  </span>
                                </td>
                                <td>{transaction.created_at}</td>
                                <td
                                >
                                  {transaction.status === "pending" ? (
                                    <span className="badge bg-warning text-capitalize">
                                      {transaction.status}
                                    </span>
                                  ) : transaction.status === "approved" ? (
                                    <span className="badge bg-success text-capitalize">
                                      {transaction.status}
                                    </span>
                                  ) : (
                                    <span className="badge bg-danger text-capitalize">
                                      {transaction.status}
                                    </span>
                                  )}
                                </td>
                                <td className="dropdown">
                                  <Link
                                    className="dropdown-toggle menu"
                                    id={`actionDropdown_${transaction.id}`}
                                    href="#"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="mdi mdi-apps"></i>
                                  </Link>
                                  <div
                                    className="dropdown-menu dropdown-menu-right preview-list"
                                    aria-labelledby={`actionDropdown_${transaction.id}`}
                                  >
                                    {/* <Link
                                      className="dropdown-item custom-drop"
                                      onClick={() => handleShow(transaction)}
                                    >
                                      <i className="mdi mdi-pencil mr-2 text-success"></i>{" "}
                                      <span>View Order</span>
                                    </Link> */}
                                    {transaction.status != 'approved' && transaction?.payment_type == 'card'  && <Link
                                      className="dropdown-item custom-drop"
                                      onClick={() => checkStatus(transaction.transaction_reference)}
                                    >
                                      <i className="mdi mdi-tag-check-outline mr-2 text-success"></i>{" "}
                                      <span>Requery</span>
                                    </Link>}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* <!-- End Table --> */}
                  </>
                ) : (
                  <div className="alert alert-info mt-4">No record found</div>
                )}
              </div>
            </div>
          </div>
          {/* <!-- End Recent Sales --> */}
          {loading && <Loader />}
        </div>
      </section>

      {/* <!-- End #main --> */}
    </>
  );
}
