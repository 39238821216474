import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import axiosInstance from "../../hooks/axiosInstance";
import { toast } from "react-toastify";

const VerifyEmail = () => {
  const verificationData = JSON.parse(localStorage.getItem("verificationData"));
  const [loading, setLoading] = useState(false);

  const appUrl =
    process.env.NODE_ENV !== "production"
      ? process.env.REACT_APP_DEV_CALL_BACK_URL
      : process.env.REACT_APP_PROD_CALL_BACK_URL;

  const callbackUrl = `${appUrl}/auth/email-verified`;

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axiosInstance
      .get(
        `/user/auth/email/resend-email?callbackUrl=${callbackUrl}&email=${verificationData.user.email}`
      )
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((err) => {
        console.log(err.response.data);
        toast.error(err.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <main style={{ backgroundColor: "#f6f9ff", height: "100vh" }}>
        <div className="container">
          <section className="section register d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-6 d-flex flex-column align-items-center justify-content-center">
                  <div className="d-flex justify-content-center py-4">
                    <Link
                      to="/"
                      className="logo d-flex align-items-center w-auto"
                    >
                      <img src="/assets/images/logo-222x66.png" alt="" />
                      {/* <span className="d-none d-lg-block">NiceAdmin</span> */}
                    </Link>
                  </div>

                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="pt-4 pb-2">
                        <h5 className="card-title text-center pb-0 fs-4">
                          Email Verification
                        </h5>
                        <div
                            className="alert border-0 alert-info text-center mb-2 mx-2"
                            role="alert"
                          >
                            Account activation link sent to your email address:
                            <b className="text-danger">
                              {" "}
                              {verificationData?.user.email}
                            </b>
                            . &nbsp; Please follow the link inside to continue.
                          </div>
                      </div>
                      <div className="col-12 text-center">
                        <p className="small mb-3">
                          Didn't get the mail? &nbsp;
                          <NavLink
                            className="fw-semibold text-primary text-decoration-underline"
                            onClick={handleSubmit}
                          >
                            {loading ? (
                              <div class="spinner-border spinner-border-sm text-danger"></div>
                            ) : (
                              "Resend"
                            )}
                          </NavLink>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};

export default VerifyEmail;
