import React, { useEffect, useState } from "react";
import "./Customer.css";
import { Link, NavLink } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Pagination, Stack, Typography } from "@mui/material";
import Loader from "../../../../components/Utilities/Loader/Loader";
import { toast } from "react-toastify";
import EditUser from "../../../../components/Modal/Admin/Users/EditUser";
import axiosInstanceAdmin from "../../../../hooks/axiosInstanceAdmin";

export default function Customers() {
  const [customers, setCustomers] = useState([]);
  const [filter, setFilter] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);

  //  state for pagination
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);

  //  pagination function
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleShowEdit = (data) => {
    setCurrentCustomer(data);
    setShowEdit(true);
  };


  const handleClose = () => {
    setShowEdit(false);
  };
  const handleRefresh = () => {
    setRefreshKey((prevRefreshKey) => prevRefreshKey + 1);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleDeleteOpen = async (data) => {
    await setCurrentCustomer(data);
    await setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleResetOpen = async (data) => {
    await setCurrentCustomer(data);
    await setResetOpen(true);
  };

  const handleResetClose = () => {
    setResetOpen(false);
  };

  const handleStatusOpen = async (data) => {
    await setCurrentCustomer(data);
    await setStatusOpen(true);
  };

  const handleStatusClose = () => {
    setStatusOpen(false);
  };

  const fetchCustomers = () => {
    setLoading(true);
    axiosInstanceAdmin
      .get(
        `/admin/users/customer?page=${page}&limit=${perPage}&filter=${filter}`
      )
      .then((response) => {
        setCustomers(response.data.data.data);
        const pageCount = Math.ceil(
          response.data.data.total / response.data.data.per_page
        );
        setPageCount(pageCount);
      })
      .catch((err) => {
        toast.error("An error occured. Please, try again");
      })
      .finally(() => setLoading(false));
  };

  const handleDelete = () => {
    setLoading(true);
    axiosInstanceAdmin
      .delete(
        `/admin/users/${currentCustomer?.id}`
      )
      .then((response) => {
        toast.success("User deleted successfully")
        setDeleteOpen(false)
        handleRefresh()
      })
      .catch((err) => {
        toast.error("An error occured. Please, try again");
      })
      .finally(() => setLoading(false));
  };

  const handleReset = () => {
    setLoading(true);
    axiosInstanceAdmin
      .post(
        `/admin/users/password/reset/${currentCustomer?.id}`
      )
      .then((response) => {
        toast.success("User's password reset successfully")
        setResetOpen(false)
        handleRefresh()
      })
      .catch((err) => {
        toast.error("An error occured. Please, try again");
      })
      .finally(() => setLoading(false));
  };

  const handleStatusChange = (status) => {
    setLoading(true);
    axiosInstanceAdmin
      .patch(
        `/admin/users/change_status/${currentCustomer?.id}`, {status}
      )
      .then((response) => {
        toast.success("User's status changed successfully")
        setStatusOpen(false)
        handleRefresh()
      })
      .catch((err) => {
        toast.error("An error occured. Please, try again");
      })
      .finally(() => setLoading(false));
  };


  const filterUsers = () => {
    fetchCustomers();
  };

  useEffect(() => {
    fetchCustomers();
  }, [page, perPage, refreshKey]);


  return (
    <>
      <div className="pagetitle">
        <h1>Customers</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/app/dashboard">Dashboard</NavLink>
            </li>
            <li className="breadcrumb-item active">Customers</li>
          </ol>
        </nav>
      </div>

      <section className="section vehicle">
        <div className="row">
          <div className="col-lg-12 col-md-12 order-2 order-sm-1 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div>
                    <h4 className="card-title d-flex align-items-center">
                      Customers
                    </h4>
                  </div>
                  {/* <div>
                    <button
                      className="vehicle-button mt-3 btn btn-primary"
                      onClick={handleShow}
                    >
                      <i className="mdi mdi-plus-circle-outline"></i> New
                      Vehicle
                    </button>
                  </div> */}
                </div>

                {/* Filter input */}
                <div className="row mb-3">
                  <div className="col-lg-3">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={filter}
                        onChange={handleFilterChange}
                      />
                      <button
                        onClick={filterUsers}
                        className="btn btn-primary"
                        type="submit"
                      >
                        Go
                      </button>
                    </div>
                  </div>
                </div>

                {customers && customers.length > 0 ? (
                  <>
                    {/* <!-- Start Table --> */}
                    <div className="scroll-area-lg">
                      <div className="table-responsive-sm">
                        <table className="table table-striped table-borderless table-hover ">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Full Name</th>
                              <th scope="col">Email</th>
                              <th scope="col">Phone</th>
                              {/* <th scope="col">Address</th> */}
                              <th scope="col">Status</th>
                              <th scope="col" width="5%"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {customers.map((customer, i) => (
                              <tr key={customer.id}>
                                <th scope="row">{i + 1}</th>
                                <td>{customer.last_name} {customer.first_name}</td>
                                <td>{customer.email}</td>
                                <td>{customer.phone ? customer.phone : '-'}</td>
                                {/* <td>{customer.address}</td> */}
                                <td>
                                  <span
                                    className={`badge text-capitalize ${
                                      customer.status == "active"
                                        ? "bg-success"
                                        : "bg-danger"
                                    }`}
                                  >
                                    {customer.status}
                                  </span>
                                </td>
                                <td className="dropdown">
                                  <Link
                                    className="dropdown-toggle menu"
                                    id={`actionDropdown_${customer.id}`}
                                    href="#"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="mdi mdi-apps"></i>
                                  </Link>
                                  <div
                                    className="dropdown-menu dropdown-menu-right preview-list"
                                    aria-labelledby={`actionDropdown_${customer.id}`}
                                  >
                                    <Link
                                      className="dropdown-item custom-drop"
                                      onClick={() => handleShowEdit(customer)}
                                    >
                                      <i className="mdi mdi-pencil mr-2 text-success"></i>{" "}
                                      <span>Edit</span>
                                    </Link>
                                    <div className="dropdown-divider"></div>
                                    {customer.status == 'inactive' 
                                    ?
                                      <Link
                                        className="dropdown-item custom-drop"
                                        onClick={() => handleStatusOpen(customer)}
                                      >
                                        <i className="mdi mdi-lock mr-2 text-secondary"></i>{" "}
                                        <span>Activate</span>
                                      </Link>
                                    :
                                      <Link
                                        className="dropdown-item custom-drop"
                                        onClick={() => handleStatusOpen(customer)}
                                      >
                                        <i className="mdi mdi-lock-open mr-2 text-secondary"></i>{" "}
                                        <span>Deactivate</span>
                                      </Link>
                                    }
                                    <div className="dropdown-divider"></div>
                                    <Link
                                      className="dropdown-item custom-drop"
                                      onClick={() => handleResetOpen(customer)}
                                    >
                                      <i className="mdi mdi-settings mr-2 text-info"></i>{" "}
                                      <span>Reset Password</span>
                                    </Link>
                                    <div className="dropdown-divider"></div>
                                    <Link className="dropdown-item custom-drop" onClick={() => handleDeleteOpen(customer)}>
                                      <i className="bi bi-trash-fill text-danger"></i>{" "}
                                      <span>Delete</span>
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end mt-2">
                      <div className="d-flex">
                        per page
                        <select
                          className="ml-1"
                          name="perPage"
                          onChange={(e) => setPerPage(e.target.value)}
                          value={perPage}
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                        </select>
                      </div>
                      <Pagination
                        onChange={handlePageChange}
                        count={pageCount}
                        color="primary"
                        shape="rounded"
                        size="small"
                        page={page}
                      />
                    </div>
                    {/* <!-- End Table --> */}
                  </>
                ) : (
                  <div className="alert alert-info mt-4">No record found</div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <Createcustomer
          show={show}
          closeModal={handleClose}
          refreshPage={handleRefresh}
        /> */}
        {currentCustomer && <EditUser
          show={showEdit}
          closeModal={handleClose}
          refreshPage={handleRefresh}
          currentUser={currentCustomer}
        />}

        {/* Delete Dialog */}
        {currentCustomer && (
          <>
            <Dialog
              open={deleteOpen}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Delete <b className="text-primary">{currentCustomer.last_name} {currentCustomer.first_name}</b>{" "}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to perform this operation?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button className="text-danger" onClick={handleDeleteClose}>
                  Cancel
                </Button>
                <Button onClick={handleDelete} autoFocus>
                  Continue
                </Button>
              </DialogActions>
              {isDeleteLoading && <Loader />}
            </Dialog>
          </>
        )}

        {/* Reset Password Dialog */}
        {currentCustomer && (
          <>
            <Dialog
              open={resetOpen}
              onClose={handleResetClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Reset Password for <b className="text-primary">{currentCustomer.last_name} {currentCustomer.first_name}</b>{" "}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to perform this operation?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button className="text-danger" onClick={handleResetClose}>
                  Cancel
                </Button>
                <Button onClick={handleReset} autoFocus>
                  Continue
                </Button>
              </DialogActions>
              {isDeleteLoading && <Loader />}
            </Dialog>
          </>
        )}

        {/* Change status Dialog */}
        {currentCustomer && (
          <>
            <Dialog
              open={statusOpen}
              onClose={handleStatusClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Change account status for <b className="text-primary">{currentCustomer.last_name} {currentCustomer.first_name}</b>{" "}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to perform this operation?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button className="text-danger" onClick={handleStatusClose}>
                  Cancel
                </Button>
                <Button onClick={() => handleStatusChange(currentCustomer.status == 'active' ? 'inactive' : 'active')} autoFocus>
                  Continue
                </Button>
              </DialogActions>
              {isDeleteLoading && <Loader />}
            </Dialog>
          </>
        )}

        {loading && <Loader />}
      </section>
    </>
  );
}
