import React, { useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../hooks/axiosInstance";
import useAuth from "../../hooks/useAuthContext";
import "./Login.css"

export default function Login() {
  const navigate = useNavigate();
  const {usertype} = useParams()

  const { userData, setUserData } = useAuth();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const reset = () => {
    setFormData({
      email: "",
      password: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.password) {
      toast.error("Password cannot be empty");
      return;
    }
    if (!formData.email) {
      toast.error("Email cannot be empty");
      return;
    }

    setLoading(true);
    setErrorMsg(null);

    localStorage.removeItem("userData");
    const url = usertype && usertype == 'admin' ? '/admin/auth/login' : '/user/auth/login'
    axiosInstance
      .post(url, formData)
      .then((res) => {
        toast.success(res.data.message);
        localStorage.setItem("userData", JSON.stringify(res.data.data));
        setUserData((prevUserData) => ({
          ...prevUserData,
          userInfo: usertype && usertype == 'admin' ? res.data.data.admin : res.data.data.user,
        }));
        reset();
        const orderUrl = localStorage.getItem('orderUrl')
        const orderData =  JSON.parse(localStorage.getItem('orderData'))
        if(orderUrl && orderData) {
          saveOrder(orderData, orderUrl)
        } else {
          navigate("/app/dashboard");
        }
      })
      .catch((err) => {
        toast.error("An error occured. Please, try again");
        setErrorMsg(err.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  const saveOrder = (orderData,orderUrl) => {
    axiosInstance.post('/user/order', orderData)
              .then(res  => {
                navigate(orderUrl)
                localStorage.removeItem('orderUrl')
                localStorage.removeItem('orderData')
              })
              .catch(err => {
                console.error(err.response)
              })
  }

  return (
    <div>
      <main style={{ backgroundColor: "#f6f9ff", height: "100vh" }}>
        <div className="container">
          <section className="section register d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-6 d-flex flex-column align-items-center justify-content-center">
                  <div className="d-flex justify-content-center py-4">
                    <Link
                      to="/"
                      className="logo d-flex align-items-center w-auto"
                    >
                      <img src="/assets/images/logo-222x66.png" alt="" />
                    </Link>
                  </div>

                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="pt-4 pb-2">
                        <h5 className="card-title text-center pb-0 fs-4">
                          Login to your Account
                        </h5>
                        <p
                          className="text-center mt-0"
                          style={{ lineHeight: 0.5 }}
                        >
                          Enter your email & password to login
                        </p>
                      </div>
                      {errorMsg && <div className='alert alert-danger p-2 mt-4 mb-0'>
                        <i className='bx bx-error'></i> {errorMsg}
                      </div>}

                      <form className="row mt-0 g-3" onSubmit={handleSubmit}>
                        <div className="col-12">
                          <label htmlFor="yourEmail" className="app-form-label">
                            Email
                          </label>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            id="yourEmail"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </div>

                        <div className="col-12">
                          <div className="d-flex justify-content-between align-items-end">
                            <label htmlFor="yourPassword" className="app-form-label">
                              Password
                            </label>
                            <NavLink className="forgot-password text-primary" to="/auth/forgot-password">
                              Forgot Password?
                            </NavLink>
                          </div>
                          <input
                            type="password"
                            name="password"
                            className="form-control"
                            id="yourPassword"
                            value={formData.password}
                            onChange={handleChange}
                            required
                          />
                        </div>

                        <div className="col-12">
                          <button
                            className="btn btn-primary w-100 my-3"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? (
                              <div className="spinner-border spinner-border-sm text-light"></div>
                            ) : (
                              "Login"
                            )}
                          </button>
                        </div>
                        <div className="col-12 text-center">
                          <p className="small mb-3">
                            Don't have an account?{" "}
                            <NavLink to="/auth/register">
                              Create an account
                            </NavLink>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
}
