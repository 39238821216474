// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
import './CreateGasPrice.css';
import axiosInstance from '../../../../hooks/axiosInstance';
import { toast } from 'react-toastify';
import Loader from '../../../Utilities/Loader/Loader';
// import axios from 'axios';

export default function ViewGasPrice({ show, currentGasPrice, closeModal, refreshPage }) {
  const [validationError, setValidationError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    quantity: "",
    discount: "",
    price: "",
    status: ""
  });

  const handleChange = (e) => {
    setFormData(prevFormData => ({ ...prevFormData, [e.target.name]: e.target.value }));
  }

  const handleClose = () => {
    closeModal()
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    axiosInstance
      .put(`/admin/gas-pricings/${currentGasPrice?.id}`, formData)
      .then((response) => {
        toast.success("Gas Price update successful");
        refreshPage();
        handleClose();
      })
      .catch((err) => {
        toast.error("An error occured. Please, try again");
        if (err.response.status == 422) {
          setValidationError(err.response.data.errors);
        }
        console.log(err.response.data);
      })
      .finally(() => setIsLoading(false));
  };


  useEffect(() => {
  }, [])

  useEffect(() => {
    setFormData({
      'quantity': currentGasPrice?.quantity,
      'price': currentGasPrice?.price,
      'discount': currentGasPrice?.discount,
      'status': currentGasPrice?.status
    })
  }, [currentGasPrice])


  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>View <span className='text-success'>Gas pricing</span> Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Form.Label>Name</Form.Label> */}
            <Form.Group className="mb-3" controlId="formBasicname">
              <div>
                <h6>Quantity (Kg)</h6>
              </div>
              <Form.Control
                type="text"
                placeholder=""
                name="quantity"
                value={formData.quantity}
                onChange={handleChange}
                required
                disabled
              />
              {validationError.quantity && (
                <small className="text-danger my-1">
                  {validationError.quantity[0]}
                </small>
              )}
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              {/* <Form.Label>Description</Form.Label> */}
              <div>
                <h6>Price (₦)</h6>
              </div>
              <Form.Control
                type="text"
                placeholder=""
                name="price"
                value={formData.price}
                onChange={handleChange}
                required
                disabled
              />
              {validationError.price && (
                <small className="text-danger my-1">
                  {validationError.price[0]}
                </small>
              )}
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              {/* <Form.Label>Description</Form.Label> */}
              <div>
                <h6>Discount (5%)</h6>
              </div>
              <Form.Control
                type="text"
                placeholder=""
                name="discount"
                value={formData.discount}
                onChange={handleChange}
                required
                disabled
              />
              {validationError.discount && (
                <small className="text-danger my-1">
                  {validationError.discount[0]}
                </small>
              )}
            </Form.Group>

            <div className="mb-3">
              <label htmlFor="status" style={{ display: "block" }}>
                <div>
                  <h6>Status</h6>
                </div>
                <select
                  className="form-control mt-2"
                  id="status"
                  value={formData.status}
                  onChange={handleChange}
                  name="status"
                  disabled
                >
                  <option value="">--Select--</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={handleClose}>
              Close
            </Button>
            {/* <Button className='custom-primary' type='submit'>
              Update
            </Button> */}
          </Modal.Footer>
        </Form>
        {isLoading && <Loader />}
      </Modal>
    </div>
  );
}
