import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppRoutes from './AppRoutes';
import { BrowserRouter as Router } from 'react-router-dom';
import "./App.css"
import useAuth from './hooks/useAuthContext';

function App() {
  const {userData} = useAuth()
  
  return (
    <>
      {userData.authIsReady &&  (
        <Router>
          <>
            <AppRoutes />
          </>
        </Router>
      )}
      <ToastContainer />
    </>
  );
}

export default App;
