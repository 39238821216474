import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../hooks/axiosInstance";
import ReactPasswordChecklist from "react-password-checklist";

export default function Register() {
  const { usertype } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    user_type: "",
    email: "",
    password: "",
    password_confirmation: "",
    company_name: "",
    first_name: "",
    last_name: ""
  });

  const [registerLoading, setRegisterLoading] = useState(false);
  const [validationError, setValidationError] = useState({});

  const [toggle, setToggle] = useState(false);
  const [toggleConfirm, setToggleConfirm] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleConfirmToggle = () => {
    setToggleConfirm(!toggleConfirm);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const resetFormData = () => {
    setFormData({
      user_type: "",
      email: "",
      password: "",
      password_confirmation: "",
      company_name: "",
      first_name: "",
      last_name: ""
    });
  };

  useEffect(() => {
    setFormData(prevFormData =>  (
      {...prevFormData, user_type: usertype == 'supplier' ? 'supplier'  : 'user'}
    ))
  }, [usertype])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.password !== formData.password_confirmation) {
      toast.error("Password do not match");
      return;
    }
    setRegisterLoading(true);
    setValidationError({});

    if(usertype == 'supplier') {
        formData.user_type = 'supplier'
    }

    axiosInstance
      .post("/user/auth/register", formData)
      .then((res) => {
        toast.success("Account Created Succesfully");
        if (res.data.data) {
          localStorage.setItem(
            "verificationData",
            JSON.stringify(res.data.data)
          );
        }
        resetFormData();
        navigate("/auth/verify-email");
      })
      .catch((err) => {
        toast.error("An error occured. Please, try again");
        if (err.response.status == 422) {
          setValidationError(err.response.data.errors);
        }
        console.log(err.response.data);
      })
      .finally(() => setRegisterLoading(false));
  };
  return (
    <div>
      <main style={{backgroundColor: '#f6f9ff', height: '100vh'}}>
        <div className="container">
          <section className="section register d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-6 d-flex flex-column align-items-center justify-content-center">
                  <div className="d-flex justify-content-center py-4">
                    <Link
                      to="/"
                      className="logo d-flex align-items-center w-auto"
                    >
                      <img src="/assets/images/logo-222x66.png" alt=""/>
                      {/* <span className="d-none d-lg-block">NiceAdmin</span> */}
                    </Link>
                  </div>

                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="pt-4 pb-2">
                        <h5 className="card-title text-center pb-0 fs-4">
                          Create an Account
                        </h5>
                        <p className="text-center mt-0" style={{lineHeight: .5}}>
                          Enter your information to create account
                        </p>
                      </div>

                      <form className="row mt-2 g-3" onSubmit={handleSubmit}>
                        <div className="col-12">
                          <label for="userType" className="app-form-label">
                            User Type
                          </label>
                          <select 
                            id="userType"
                            name="user_type"
                            className="form-control custom-select"
                            value={formData.user_type}
                            onChange={handleChange}
                          >
                            <option value="user">User</option>
                            <option value="supplier">Supplier</option>
                          </select>
                          {validationError.first_name && (<small className="text-danger mt-1 mb-0">{validationError.first_name[0]}</small>)}
                        </div>
                        <div className="col-12">
                          <label for="firstName" className="app-form-label">
                            First Name
                          </label>
                          <input
                            id="firstName"
                            type="text"
                            name="first_name"
                            className="form-control"
                            value={formData.first_name}
                            onChange={handleChange}
                          />
                          {validationError.first_name && (<small className="text-danger mt-1 mb-0">{validationError.first_name[0]}</small>)}
                        </div>

                        <div className="col-12">
                          <label for="lastName" className="app-form-label">
                            Last Name
                          </label>
                          <input
                            id="lastName"
                            type="text"
                            name="last_name"
                            className="form-control"
                            value={formData.last_name}
                            onChange={handleChange}
                            required
                          />
                          {validationError.last_name && (<small className="text-danger mt-1 mb-0">{validationError.last_name[0]}</small>)}
                        </div>

                        {formData.user_type == 'supplier' && (
                            <div className="col-12">
                                <label for="companyName" className="app-form-label">
                                    Company Name
                                </label>
                                <input
                                    id="companyName"
                                    type="text"
                                    name="company_name"
                                    className="form-control"
                                    value={formData.company_name}
                                    onChange={handleChange}
                                    required
                                />
                                {validationError.company_name && (<small className="text-danger mt-1 mb-0">{validationError.company_name[0]}</small>)}
                            </div>
                        )}

                        <div className="col-12">
                          <label for="yourEmail" className="app-form-label">
                            Email
                          </label>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            id="yourEmail"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                          {validationError.email && (<small className="text-danger mt-1 mb-0">{validationError.email[0]}</small>)}
                        </div>

                        <div className="col-12">
                          <label for="yourPassword" className="app-form-label">
                            Password
                          </label>
                          <input
                            type="password"
                            name="password"
                            className="form-control"
                            id="yourPassword"
                            value={formData.password}
                            onChange={handleChange}
                            required
                          />
                          {validationError.password && (<small className="text-danger mt-1 mb-0">{validationError.password[0]}</small>)}
                          {formData.password && (
                              <ReactPasswordChecklist
                                  className="p-3 bg-light mb-2 mt-2 rounded"
                                  rules={[
                                      "minLength",
                                      "specialChar",
                                      "number",
                                      "capital",
                                      "match",
                                  ]}
                                  minLength={8}
                                  value={formData.password}
                                  valueAgain={formData.password_confirmation}
                              />
                          )}
                        </div>

                        <div className="col-12">
                          <label for="confirmPassword" className="app-form-label">
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            name="password_confirmation"
                            className="form-control"
                            id="confirmPassword"
                            value={formData.password_confirmation}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="col-12">
                          <input className="form-check-input me-2" type="checkbox" id="gridCheck1" required/>
                          <span>I accept the <a href="/terms.pdf" target='_blank' className={'text-primary'}><b>terms & conditions</b></a> of service</span>
                        </div>
                        
                        <div className="col-12">
                          <button className="btn btn-primary w-100 my-3" type="submit" disabled={registerLoading}>
                            {registerLoading ? <div className="spinner-border spinner-border-sm text-light"></div> : 'Create Account '}
                          </button>
                        </div>
                        <div className="col-12 text-center">
                          <p className="small mb-3">
                            Already have an account?{" "}
                            <NavLink to="/auth/login">Log in</NavLink>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
}
