import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./NewRequest.css";
import data from "../../../data";
import LocationInput from "../../../components/FormComponents/LocationInput";
import axiosInstance from "../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuthContext";
import { Dialog, DialogContent, DialogContentText } from "@mui/material";
import SuccessPage from "../../../components/Utilities/SuccessPage/SuccessPage";

export default function NewRequest() {
  const {userData}  =  useAuth()
  const [selectedImg, setSelectedImg] = useState('https://easygasng.com/wp-content/uploads/2015/11/Refill.jpg')

  const [isDeliverable, setIsDeliverable] = useState(true)
  const [totalAmount, setTotalAmount]  = useState(null)
  const [loading,setLoading]  =  useState(false)
  const [orderSuccess,  setOrderSuccess] = useState(false)

  const [formData, setFormData] = useState({
    currentAddress: "",
    coordinates: "",
    quantity: '',
    priority: '',
    phone: '',
    currentAddress: "",
    coordinates: "",
    total_amount: ""
  });

  const navigate = useNavigate()

  const handleChange = (e) => {
    const {name, value} = e.target

    setFormData(prevFormData => (
      {...prevFormData, [name]: value}
    ))
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if(!isDeliverable) {
      toast.error("Order cannot be fuflfilled for selected area")
      return
    } 
    setLoading(true)
    formData.total_amount = totalAmount
    axiosInstance.post('/user/order', formData)
              .then(res  => {
                localStorage.removeItem('orderData')
                // localStorage.setItem('orderUrl', "/app/user/order/details")
                setOrderSuccess(true)
                showOrder()
              })
              .catch(err => {
                console.error(err.response)
              })
              .finally(() => setLoading(false))
  };

  const showOrder = ()  => {
    setTimeout(() => {
      setOrderSuccess(false)
      navigate("/app/user/order/details");
    }, 2000);
  }

  const checkDeliverability = (status) => {
    setIsDeliverable(status)
  }

  const setInputAddress = (address) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      currentAddress: address,
    }));
  }

  const setInputCoordinates = (coordinates) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      coordinates: coordinates,
    }));
  };

  const fetchGasPricing = () => {
    axiosInstance.get('/generic/gas_pricing')
        .then(res => {
          const total  = formData.quantity * res.data.data.items[0].price
          setTotalAmount(total)
        })
  }

  useEffect(() =>  {
    const orderData = localStorage.getItem('orderData');

    if(orderData) {
      setFormData(JSON.parse(orderData))
    }
  }, [])

  useEffect(() =>  {
    if(userData.userInfo && userData.userInfo.user_type == 'user') {
      setFormData((prevFormData) => (
        {...prevFormData, phone: userData.userInfo.phone}
      ))
    }
  }, [userData])

  useEffect(() => {
    fetchGasPricing()
  }, [formData.quantity])

  return (
    <>
      <div className="pagetitle">
        <h1>Gas Request</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/app/user/dashboard">Dashboard</NavLink>
            </li>
            <li className="breadcrumb-item">Order</li>
            <li className="breadcrumb-item active">New Request</li>
          </ol>
        </nav>
      </div>
      {/* <!-- End Page Title --> */}

      <section className="section request-wrapper">
        <div className="row">
          <div className="col-lg-9 order-lg-1 order-2">
            <div className="card">
              <div className="card-body py-5 px-lg-5">
                {/* <h5 className="card-title">New Request</h5> */}
                <div className="row">
                  <div className="col-lg-5 d-flex align-items-center justify-content-center">
                    <img src={selectedImg} alt="" />
                  </div>
                  <div className="col-lg-7">
                    <form className="row g-3" onSubmit={handleSubmit}>
                      <div className="col-md-12 mb-3">
                        <input
                          className="form-select form-control-lg"
                          id="form-quantity"
                          type="number"
                          placeholder="Quantity (kg)"
                          name="quantity"
                          onChange={handleChange}
                          value={formData.quantity}
                          required
                        />
                      </div>
                      <div className="col-md-12 mb-3">
                        <select id="inputState" 
                          className="form-select form-control-lg"
                          name="priority"
                          onChange={handleChange}
                          value={formData.priority}
                          required
                        >
                          <option value="">Priority</option>
                          <option value="high">High</option>
                          <option value="medium">Medium</option>
                          <option value="low">Low</option>
                        </select>
                      </div>
                      <div className="col-md-12 mb-3">
                        <LocationInput 
                          formWrapClass='form-wrap'
                          name='currentAddress'
                          inputClass='form-control form-control-lg'
                          handleChange={handleChange}
                          formValue={formData.currentAddress}
                          setInputAddress={setInputAddress}
                          setInputCoordinates={setInputCoordinates}
                          checkDeliverability={checkDeliverability}
                          locationClass='d-flex gap-3 special'
                          required={true}
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <input 
                          type="text" 
                          className="form-control form-control-lg" 
                          placeholder="Your Phone Number"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange} 
                        />
                      </div>
                      <div className="col-md-12 my-3">
                        <button type="submit" className="btn btn-primary py-3 w-100" disabled={loading}>
                          { loading ? 'Submitting...' : 'Proceed with order'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 order-lg-2 order-1 mb-3">
              <div className="card">
                <div className="card-header">
                    <h5 className="font-weight-normal">Order Summary</h5>
                </div>
                <div className="card-body">
                    <div className="d-flex items">
                        <div>
                            <p>Item total</p>
                        </div>
                        <div>
                            <p>₦{Number(totalAmount).toLocaleString()}</p>
                        </div>
                    </div>
                    <div className="d-flex items">
                        <div>
                            <p>Delivery Fees</p>
                        </div>
                        <div>
                            <p className="text-primary" style={{fontSize: '12px'}}>Not calculated yet</p>
                        </div>
                    </div>
                    <div className="d-flex items">
                        <div>
                            <p>Total</p>
                        </div>
                        <div>
                            <p>₦{Number(totalAmount).toLocaleString()}</p>
                        </div>
                    </div>
                </div>
              </div>
            </div>
        </div>
      </section>

      <>
        <Dialog
          open={orderSuccess}
          // onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* <DialogTitle id="alert-dialog-title">
            Delete <b className="text-primary">{currentVehicle.brand_name} {currentVehicle.model}</b>{" "}
          </DialogTitle> */}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <SuccessPage 
                heading={'Order Successul'}
                subHeading={'Your order was placed successfully.'}
                subHeading2={'You will get notified  once  our  supplier accepts!'}
              />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </>
    </>
  );
}
