import React from 'react'
import useAuth from '../../hooks/useAuthContext'
import CustomerDashboard from '../Client/Dashboard/Dashboard'
import SupplierDashboard from '../Supplier/Dashboard/Dashboard'
import AdminDashboard from '../Admin/Dashboard/Dashboard'

const Index = () => {
    const {userData} = useAuth()
  return (
    <div>
      {
        userData && userData.userInfo?.user_type == 'user' ?
            <CustomerDashboard />
        :
        userData && userData.userInfo?.user_type == 'supplier' ?
            <SupplierDashboard />
        :
        <AdminDashboard />
      }
    </div>
  )
}

export default Index
