import React, { useEffect, useRef } from 'react';
import H from '@here/maps-api-for-javascript';

const Map = ({ customerLocation, supplierLocation }) => {
    const apikey = process.env.REACT_APP_HERE_MAP_API_KEY

    const mapRef = useRef(null);
    const map = useRef(null);
    const platform = useRef(null);

    const getMarkerIcon = (url) => {
        return new H.map.Icon(url);
    }

    const calculateRoute  = (platform, map, start, destination) => {
        function routeResponseHandler(response) {
            const sections = response.routes[0].sections;
            
            const lineStrings = [];
            sections.forEach((section) => {
                // convert Flexible Polyline encoded string to geometry
                lineStrings.push(H.geo.LineString.fromFlexiblePolyline(section.polyline));
            });
            const multiLineString = new H.geo.MultiLineString(lineStrings);
            const bounds = multiLineString.getBoundingBox();
    
            // Create the polyline for the route
            const routePolyline = new H.map.Polyline(multiLineString, {
                style: {
                    lineWidth: 5
                }
            });
    
            // Remove all the previous map objects, if any
            map.removeObjects(map.getObjects());
            // Add the polyline to the map
            map.addObject(routePolyline);
            map.addObjects([
                // Add a marker for the user
                new H.map.Marker(start, {
                    icon: getMarkerIcon('https://img.icons8.com/color/48/standing-man.png')
                }),
                // Add a marker for the selected restaurant
                new H.map.Marker(destination, {
                    icon: getMarkerIcon('https://img.icons8.com/external-filled-outline-design-circle/64/external-Gas-Truck-industry-filled-outline-design-circle.png')
                })
            ]);
            // Configure the map view to automatically zoom into the bounds 
            // encompassing markers and route polyline:
            map.getViewModel().setLookAtData({ bounds });
        }
    
        // Get an instance of the H.service.RoutingService8 service
        const router = platform.getRoutingService(null, 8);
    
        // Define the routing service parameters
        const routingParams = {
            'origin': `${start.lat},${start.lng}`,
            'destination': `${destination.lat},${destination.lng}`,
            'transportMode': 'car',
            'return': 'polyline'
        };
        // Call the routing service with the defined parameters
        router.calculateRoute(routingParams, routeResponseHandler, console.error);
    }

    useEffect(() => {
        // Check if the map object has already been created
        if (!map.current && customerLocation && supplierLocation) {
            // Create a platform object with the API key and useCIT option
            platform.current = new H.service.Platform({
                apikey
            });
            // Obtain the default map types from the platform object:
            const defaultLayers = platform.current.createDefaultLayers({
                pois: true
            });
            // Create a new map instance with the Tile layer, center and zoom level
            // Instantiate (and display) a map:
            const newMap = new H.Map(
                mapRef.current,
                defaultLayers.vector.normal.map, {
                    zoom: 12,
                    center: customerLocation,
                }
            );

            // Add panning and zooming behavior to the map
            const behavior = new H.mapevents.Behavior(
                new H.mapevents.MapEvents(newMap)
            );

            // Set the map object to the reference
            map.current = newMap;

            const ui = H.ui.UI.createDefault(newMap, defaultLayers)

        }
        if (supplierLocation) {
            calculateRoute(platform.current, map.current, customerLocation, supplierLocation);
        }
    }, [customerLocation, apikey, supplierLocation]);

    // Return a div element to hold the map
    return (
        <>
            <div className="">
                <div style={{ width: "100%", height: "500px", backgroundColor: '#f3f3f4' }} ref={mapRef} />
            </div>
        </>
    )
}

export default Map;