// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import "./CreateDeliveryPrice.css";
import axiosInstance from "../../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import Loader from "../../../Utilities/Loader/Loader";
// import axios from 'axios';

export default function CreateVehicle({ show, closeModal, refreshPage }) {
  const [validationError, setValidationError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    max_distance: "",
    min_distance: "",
    price: "",
    status: ""
  });

  const resetForm = () => {
    setFormData({
      max_distance: "",
      min_distance: "",
      price: "",
      status: ""
    });
    setValidationError({});
  };

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleClose = () => {
    resetForm();
    closeModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    axiosInstance
      .post(`/admin/delivery-pricings`, formData)
      .then((response) => {
        toast.success(response.data.data.message);
        refreshPage();
        handleClose();
      })
      .catch((err) => {
        toast.error("An error occured. Please, try again");
        if (err.response.status == 422) {
          setValidationError(err.response.data.errors);
        }
        console.log(err.response.data);
      })
      .finally(() => setIsLoading(false));
  };

  

  useEffect(() => {}, [])

  return (
    <div className="CreateVehicle">
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Add Vehicle Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Form.Label>Name</Form.Label> */}
            
            <Form.Group className="mb-3" controlId="formBasicname">
              <div>
                <h6>Min Distance (Km)</h6>
              </div>
              <Form.Control
                type="number"
                placeholder=""
                name="min_distance"
                value={formData.min_distance}
                onChange={handleChange}
                required
              />
              {validationError.min_distance && (
                <small className="text-danger my-1">
                  {validationError.min_distance[0]}
                </small>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicname">
              <div>
                <h6>Max Distance (Km)</h6>
              </div>
              <Form.Control
                type="number"
                placeholder=""
                name="max_distance"
                value={formData.max_distance}
                onChange={handleChange}
                required
              />
              {validationError.max_distance && (
                <small className="text-danger my-1">
                  {validationError.max_distance[0]}
                </small>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicname">
              <div>
                <h6>Price (₦)</h6>
              </div>
              <Form.Control
                type="text"
                placeholder=""
                name="price"
                value={formData.price}
                onChange={handleChange}
                required
              />
              {validationError.price && (
                <small className="text-danger my-1">
                  {validationError.price[0]}
                </small>
              )}
            </Form.Group>


            <div className="mb-3">
              <label htmlFor="status" style={{ display: "block" }}>
                <div>
                  <h6>Status</h6>
                </div>
                <select
                  className="form-control mt-2"
                  id="status"
                  value={formData.status}
                  onChange={handleChange}
                  name="status"
                >
                  <option value="">--Select--</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={handleClose}>
              Close
            </Button>
            <Button className="custom-primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
        {isLoading && <Loader />}
      </Modal>
    </div>
  );
}
