import React, { useEffect, useState } from "react";
import Header from "../../components/WebLayouts/Header";
import Footer from "../../components/WebLayouts/Footer";
import { NavLink, useNavigate } from "react-router-dom";
import LocationInput from "../../components/FormComponents/LocationInput";
import useAuth from "../../hooks/useAuthContext";
import nearbySupplierService from "../../services/nearbySuppliers";
import { toast } from "react-toastify";

export default function LandingPage() {
  const {userData} = useAuth()
  const navigate = useNavigate();

  const [isDeliverable, setIsDeliverable] = useState(true)

  const [formData, setFormData] = useState({
    quantity: '',
    priority: '',
    phone: '',
    currentAddress: "",
    coordinates: "",
  });

  const clearFormData = ()  => {
    setFormData({
      quantity: '',
      priority: '',
      phone: '',
      currentAddress: "",
      coordinates: "",
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const checkDeliverability = (status) => {
    setIsDeliverable(status)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if(!isDeliverable) {
      toast.error("Order cannot be fuflfilled for selected area")
      return
    } 
    localStorage.setItem('orderData', JSON.stringify(formData))
    clearFormData()
    navigate('/order')
  };

  const setInputAddress = (address) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      currentAddress: address,
    }));
  };

  const setInputCoordinates = (coordinates) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      coordinates: coordinates,
    }));
  };

  useEffect(() =>  {
    if(userData.userInfo && userData.userInfo.user_type == 'user') {
      setFormData((prevFormData) => (
        {...prevFormData, phone: userData.userInfo.phone}
      ))
    }
  }, [userData])

  return (
    <div className="wrapper">
      <Header />
      {/* <!-- Mobile Navbar --> */}
      <div className="left-sidebar d-xl-none">
        <div className="nav_wrap">
          <ul>
            {userData.userInfo && 
              <li className="text-capitalize">
                <NavLink to="/app/dashboard" className={'text-dark'}>
                  Dashboard
                </NavLink>
              </li>
            }
            <li className="text-capitalize">get our app</li>
          </ul>
        </div>
      </div>

      <div className="right-sidebar">
        <div className="nav_wrap_one">
          <div className="position-relative px-4 py-4">
            <h5 className="mb-0 sidebar-title text-uppercase">navigation</h5>
            <p className="close mb-0 ">x</p>
          </div>
          <ul>
            <li className="text-capitalize border-top border-bottom">about us</li>
            <li className="text-uppercase border-bottom">faq</li>
            <li className="text-capitalize border-bottom">testimonials</li>
            <li className="text-capitalize border-bottom">our team</li>
          </ul>
        </div>
      </div>

      {/* Hero Section */}
      <div className="wrapss">
        <div className="container-fluid hero">
          <div className="row heroWrap">
            <div className="overlay d-xl-flex"></div>
            <div className="col-12 col-xl-6">
              <div className="d-flex justify-content-center align-items-center my-0 my-xl-6 py-lg-4 py-xl-0 hero-img">
                <img
                  src="/assets/images/idnex-1-747x597.png"
                  width="80%"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="form_input col-12">
              <div className="_form">
                <h2 className="text-uppercase">Seamless gas at your doorstep</h2>
                <div className="contact d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="currentColor"
                    className="bi bi-telephone-inbound-fill me-4"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877zM15.854.146a.5.5 0 0 1 0 .708L11.707 5H14.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 1 0v2.793L15.146.146a.5.5 0 0 1 .708 0"
                    />
                  </svg>
                  <p className="mb-0 telephone">09090000130</p>
                </div>
                <p className="trust-the-leading-operator">
                  Trust the leading and the most reliable gas operator.
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="input-wrap mb-4">
                    <input
                      type="number"
                      name="quantity"
                      onChange={handleChange}
                      value={formData.quantity}
                      className="_input"
                      placeholder="Quantity (kg)"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <select 
                      name="priority"
                      onChange={handleChange}
                      value={formData.priority}
                      required
                    >
                      <option value="">Priority</option>
                      <option value="high">High</option>
                      <option value="medium">Medium</option>
                      <option value="low">Low</option>
                    </select>
                  </div>
                  <LocationInput
                    formWrapClass="mb-4"
                    name="currentAddress"
                    inputClass="input-wrap-2"
                    handleChange={handleChange}
                    formValue={formData.currentAddress}
                    setInputAddress={setInputAddress}
                    setInputCoordinates={setInputCoordinates}
                    checkDeliverability={checkDeliverability}
                    locationClass="d-flex gap-3 address-control"
                    required={true}
                  />
                  <div className="input-number mb-4 row gap-0">
                    <div className="col-12 col-sm-8 number-input-wrap mb-4 mb-sm-0">
                      <input
                        type="number"
                        className="_input"
                        placeholder="Your Phone Number"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 col-sm-4 d-sm-flex justify-content-end btn_wrap ps-sm-0">
                      <button className="text-uppercase px-3">order now</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!--services--> */}
      {/* <div className="container-fluid services position-relative">
        <div className="container py-5">
          <h1 className="text-uppercase fw-bold">our services</h1>
          <div className="cards row">
            <div className="col-12 col-sm-6 col-lg-4 mb-5">
              <div className="service-card pt-5">
                <div className="img">
                  <div className="box-modern-circle"></div>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path
                      fill="#09b294"
                      d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"
                    />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path
                      fill="#09b294"
                      d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                    />
                  </svg>
                  <div className="box-modern-circle-1"></div>
                </div>
                <div className="wrap">
                  <h4 className="text-uppercase mb-4">private driver</h4>
                  <p>
                    Enjoy the best customer service from our professional
                    drivers.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 mb-5">
              <div className="service-card">
                <div className="img">
                  <div className="box-modern-circle"></div>
                  <svg
                    style={{ transform: "rotate(270deg) !important" }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                  >
                    <path
                      fill="#09b294"
                      d="M482.3 192c34.2 0 93.7 29 93.7 64c0 36-59.5 64-93.7 64l-116.6 0L265.2 495.9c-5.7 10-16.3 16.1-27.8 16.1l-56.2 0c-10.6 0-18.3-10.2-15.4-20.4l49-171.6L112 320 68.8 377.6c-3 4-7.8 6.4-12.8 6.4l-42 0c-7.8 0-14-6.3-14-14c0-1.3 .2-2.6 .5-3.9L32 256 .5 145.9c-.4-1.3-.5-2.6-.5-3.9c0-7.8 6.3-14 14-14l42 0c5 0 9.8 2.4 12.8 6.4L112 192l102.9 0-49-171.6C162.9 10.2 170.6 0 181.2 0l56.2 0c11.5 0 22.1 6.2 27.8 16.1L365.7 192l116.6 0z"
                    />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path fill="#09b294" d="3-32-32-32H256V80z" />
                  </svg>
                  <div className="box-modern-circle-1"></div>
                </div>
                <div className="wrap">
                  <h4 className="text-uppercase mb-4">airport transfer</h4>
                  <p>
                    Comfortable transfer services from airport to any chosen
                    address.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <div className="service-card">
                <div className="img">
                  <div className="box-modern-circle"></div>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path
                      fill="#09b294"
                      d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"
                    />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path
                      fill="#09b294"
                      d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                    />
                  </svg>
                  <div className="box-modern-circle-1"></div>
                </div>
                <div className="wrap">
                  <h4 className="text-uppercase mb-4">baggage transfer</h4>
                  <p>
                    If you need to collect your baggage, we are ready to help
                    you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* app download */}
      <div className="container-fluid download-app">
        <div className="row">
          <div className="col-12 col-md-6 column_1">
            <div className="download">
              <h3 className="text-uppercase">download the app</h3>
              <div className="tabs mt-4">
                <div className="tab-headers d-flex">
                  <p className="active me-md-4 mb-0 mb-md-3">details</p>
                  <p>how it works</p>
                </div>
                <div className="tab-content">
                  <div className="mt-3 tab-content-child active">
                    <p className="p-main">
                      Download our app and enjoy the best service from Neon Gas. 
                      All you have to do to begin using it is to sign up and place an order.
                    </p>
                    <div className="list_wrap">
                      <div className="list_style_wrap d-flex align-items-center">
                        <div></div>
                        <p className="mb-0">Secure Payment</p>
                      </div>
                      <div className="list_style_wrap d-flex align-items-center">
                        <div></div>
                        <p className="mb-0">Instant Delivery</p>
                      </div>
                      <div className="list_style_wrap d-flex align-items-center">
                        <div></div>
                        <p className="mb-0">Transparent refil</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 tab-content-child">
                    <p className="p-main">
                      NeonGas App allows you to order for a gas refil without having to leave the 
                      confine of your home as well as set your route in advance:
                    </p>
                    <div className="list_wrap">
                      <div className="list_style_wrap d-flex align-items-center">
                        <div></div>
                        <p className="mb-0">Manage your taxi spendings;</p>
                      </div>
                      <div className="list_style_wrap d-flex align-items-center">
                        <div></div>
                        <p className="mb-0">Pay for taxi without a credit card;</p>
                      </div>
                      <div className="list_style_wrap d-flex align-items-center">
                        <div></div>
                        <p className="mb-0">Great discounts for regular clients.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="stores mt-5">
                  <img
                    src="./assets/images/button-1-170x53.png"
                    className="img-fluid me-4"
                    alt=""
                  />
                  <img
                    src="./assets/images/button-2-170x53.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 d-sm-flex d-md-block align-items-center column_2">
            <div className="img_wrap">
              <img
                src="./assets/images/frame-1-533x868.png"
                className="img-fluid"
                alt=""
                width={"533"}
              />
            </div>
          </div>
        </div>
      </div>

      {/* stickers */}
      <div className="section section-1 text-center" id="stickers">
        
      </div>

      {/* <!-- Testimonials--> */}
      <div className="testimonials text-center">
        <div className="container">
          <h3 className="text-uppercase my-5">Testimonials</h3>
          <div className="clients">
            <div className="row clients-data">
              <div className="col-md-6 col-lg-4 testimony">
                <blockquote className="quotes">
                  <div className="quotes_head">
                    <div className="quotes-info">
                      {/* <img
                        src="/assets/images/testimonials-1-74x74.jpg"
                        className="quotes_img"
                      /> */}
                      <div className="quotes-info-main">
                        <cite className="quotes-cite">JANE WILLIAMS</cite>
                        {/* <p>Regular Client</p> */}
                      </div>
                    </div>
                  </div>
                  <div className="quote">
                    <div className="quote-text">
                      <p className="">
                        Neon Gas has been a game-changer for me. The convenience of being able to order gas 
                        from my phone and have it delivered quickly is unbeatable. 
                        The platform connects me with the best local suppliers, 
                        ensuring competitive prices and excellent service. 
                        I appreciate the transparency and reliability, and I 
                        recommend this service to anyone looking for a hassle-free gas ordering experience.
                      </p>
                    </div>
                    {/* <div className="quote_logo">
                      <a className="quote-link">
                        <i
                          className="bi bi-facebook"
                          style={{ color: "#6885cf", fontSize: "40px" }}
                        ></i>
                      </a>
                      <time className="quote-time" datetime="2024">
                        Mar 24, 2024
                      </time>
                    </div> */}
                  </div>
                </blockquote>
              </div>
              <div className="col-md-6 col-lg-4">
                <blockquote className="quotes">
                  <div className="quotes_head">
                    <div className="quotes-info">
                      {/* <img
                        src="/assets/images/testimonials-2-74x74.jpg"
                        className="quotes_img"
                      /> */}
                      <div className="quotes-info-main">
                        <cite className="quotes-cite">OLA AINA</cite>
                        {/* <p>Regular Client</p> */}
                      </div>
                    </div>
                  </div>
                  <div className="quote">
                    <div className="quote-text">
                      <p className="">
                        After a friend recommended Neon Gas I decided to give it a try, 
                        and I couldn't be happier. The website's intuitive design 
                        makes it easy to place an order, and I love how I can 
                        track my delivery in real time. The suppliers have been 
                        reliable and professional, ensuring I never run out of gas. 
                        This service has made my life so much easier, and I will continue 
                        to use it regularly. 
                      </p><br />
                    </div>
                    {/* <div className="quote_logo">
                      <a className="quote-link">
                        <i
                          className="bi bi-facebook"
                          style={{ color: "#6885cf", fontSize: "40px" }}
                        ></i>
                      </a>
                      <time className="quote-time" datetime="2024">
                        Mar 24, 2024
                      </time>
                    </div> */}
                  </div>
                </blockquote>
              </div>
              <div className="col-md-6 col-lg-4">
                <blockquote className="quotes">
                  <div className="quotes_head">
                    <div className="quotes-info">
                      {/* <img
                        src="/assets/images/testimonials-3-74x74.jpg"
                        className="quotes_img"
                      /> */}
                      <div className="quotes-info-main">
                        <cite className="quotes-cite">SEGUN OGUNGBEMI</cite>
                        {/* <p>Regular Client</p> */}
                      </div>
                    </div>
                  </div>
                  <div className="quote">
                    <div className="quote-text">
                      <p className="">
                        I recently started using Neon Gas for my gas fill-up needs, 
                        and I am thoroughly impressed. The ordering process is 
                        simple and user-friendly, allowing me to quickly find 
                        the nearest suppliers. The deliveries are always on time, 
                        and the customer service team is incredibly responsive. 
                        It's such a relief to know I can count on this service 
                        whenever I need it. Highly recommended!
                      </p>
                    </div>
                    {/* <div className="quote_logo">
                      <a className="quote-link">
                        <i
                          className="bi bi-facebook"
                          style={{ color: "#6885cf", fontSize: "40px" }}
                        ></i>
                      </a>
                      <time className="quote-time" datetime="2024">
                        Mar 24, 2024
                      </time>
                    </div> */}
                  </div>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="sponsors">
          <h3>Our Partners</h3>
          <div className="underlay"></div>
          <div
            className="owl-carousel owl-carousel-centered"
            data-items="2"
            data-sm-items="3"
            data-md-items="4"
            data-lg-items="5"
            data-stage-padding="0"
            data-loop="true"
            data-margin="30"
            data-mouse-drag="false"
            data-owl='{"dots":true}'
          >
            <div className="wow clipInLeft">
              <a className="link-image-1" href="#">
                <img
                  src="/assets/images/investor-1-88x77.png"
                  alt=""
                  width="88"
                  height="77"
                  loading="lazy"
                />
              </a>
            </div>
            <div className="wow clipInLeft" data-wow-delay=".1s">
              <a className="link-image-1" href="#">
                <img
                  src="/assets/images/investor-2-73x78.png"
                  alt=""
                  width="73"
                  height="78"
                  loading="lazy"
                />
              </a>
            </div>
            <div className="wow clipInLeft" data-wow-delay=".2s">
              <a className="link-image-1" href="#">
                <img
                  src="/assets/images/investor-3-96x62.png"
                  alt=""
                  width="96"
                  height="62"
                  loading="lazy"
                />
              </a>
            </div>
            <div className="wow clipInLeft" data-wow-delay=".3s">
              <a className="link-image-1" href="#">
                <img
                  src="/assets/images/investor-4-78x80.png"
                  alt=""
                  width="78"
                  height="80"
                  loading="lazy"
                />
              </a>
            </div>
            <div className="wow clipInLeft" data-wow-delay=".4s">
              <a className="link-image-1" href="#">
                <img
                  src="/assets/images/investor-5-87x76.png"
                  alt=""
                  width="87"
                  height="76"
                  loading="lazy"
                />
              </a>
            </div>
          </div>
        </div> */}
      </div>
      <Footer />
    </div>
  );
}
