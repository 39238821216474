import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import axiosInstance from "../../../../hooks/axiosInstance";
import Loader from "../../../Utilities/Loader/Loader";

export default function ViewGasHistory({ show, currentVehicle, closeModal }) {
  const [gasHistory, setGasHistory] = useState([]);
  const [loading, setLoading] = useState(true)
  const [filter, setFilter] = useState({
    from: "",
    to: "",
  });

  //  state for pagination
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);

  //  pagination function
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleClose = () => {
    closeModal();
  };

  const handleFilterChange = (e) => {
    const {name, value} = e.target;
    setFilter(prevFilter => ({ ...prevFilter, [name]: value }));
  };

  const fetchGasHistory = () => {
    setLoading(true);
    axiosInstance
      .get(
        `/user/supplier/vehicle/history/${currentVehicle?.id}?page=${page}&limit=${perPage}&from=${filter.from}&to=${filter.to}`
      )
      .then((response) => {
        setGasHistory(response.data.data.data);
        const pageCount = Math.ceil(
          response.data.data.total / response.data.data.per_page
        );
        setPageCount(pageCount);
      })
      .catch((err) => {
        toast.error("An error occured. Please, try again");
      })
      .finally(() => setLoading(false));
  };

  const clearFilter = () => {
    setFilter({
      from: "",
      to: ""
    })
  }

  const filterGasHistory = () => {
    if(filter.to == '' || filter.from == '') {
      toast.error("Please select a From and To date")
      return
    }
    fetchGasHistory();
  };

  useEffect(() => {
    fetchGasHistory();
  }, [currentVehicle, page, perPage]);

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            View{" "}
            <span className="text-success">
              {currentVehicle?.brand_name} {currentVehicle?.model}
            </span>{" "}
            gas history
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className="section vehicle">
            <div className="row">
              <div className="col-lg-12 col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body pt-4">
                    {/* Filter input */}
                    <div className="row mb-3">
                      <div className="col-lg-4">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              From
                            </div>
                            </div>
                            <input
                              type="date"
                              className="form-control"
                              name="from"
                              value={filter.from}
                              onChange={handleFilterChange}
                            />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              To
                            </div>
                            </div>
                            <input
                              type="date"
                              className="form-control"
                              name="to"
                              value={filter.to}
                              onChange={handleFilterChange}
                            />
                        </div>
                      </div>
                      {(filter.from != '' || filter.to != '') &&
                        <div className="col-lg-4">
                          <button className="btn btn-primary" onClick={filterGasHistory}>
                            Fetch
                          </button>
                          <button onClick={clearFilter} className="btn btn-danger mx-3">
                            Clear
                          </button>
                        </div>
                      }
                    </div>

                    {gasHistory && gasHistory.length > 0 ? (
                      <>
                        {/* <!-- Start Table --> */}
                        <div className="scroll-area-lg">
                          <div className="table-responsive-sm">
                            <table className="table table-striped table-borderless table-hover ">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Type</th>
                                  <th scope="col">Quantity (kg)</th>
                                  <th scope="col">Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {gasHistory.map((history, i) => (
                                  <tr key={history.id}>
                                    <th scope="row">{i + 1}</th>
                                    <td>
                                      <span
                                        className={`badge text-capitalize ${
                                          history.type == "refill"
                                            ? "bg-success"
                                            : "bg-danger"
                                        }`}
                                      >
                                        {history.type}
                                      </span>
                                    </td>
                                    <td>{history.quantity}</td>
                                    <td>{history.created_at}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end mt-2">
                          <div className="d-flex">
                            per page
                            <select
                              className="ml-1"
                              name="perPage"
                              onChange={(e) => setPerPage(e.target.value)}
                              value={perPage}
                            >
                              <option value="5">5</option>
                              <option value="10">10</option>
                              <option value="15">15</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                            </select>
                          </div>
                          <Pagination
                            onChange={handlePageChange}
                            count={pageCount}
                            color="primary"
                            shape="rounded"
                            size="small"
                            page={page}
                          />
                        </div>
                        {/* <!-- End Table --> */}
                      </>
                    ) : (
                      <div className="alert alert-info mt-4">
                        No record found
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {loading && <Loader />}
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={handleClose}>
            Close
          </Button>
          {/* <Button className='custom-primary' type='submit'>
              Update
            </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
