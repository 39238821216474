import React, { useEffect, useState } from "react";
import "./Support.css";
import { Link, NavLink } from "react-router-dom";
import { Pagination, Stack, Typography } from "@mui/material";
import ViewAdminSupport from "../../../components/Modal/ViewAdminSupport";

export default function Support() {
  const [contact, setContact] = useState([
    {
      id: 1,
      name: "Brandon Jacob",
      email: "admin@admin.com",
      subject: "Transaction not working properly",
    },
    {
      id: 2,
      name: "Jacob James",
      email: "client@client.com",
      subject: "Transaction not working well",
    },
    {
      id: 3,
      name: "Ajayi Jacob",
      email: "user@user.com",
      subject: "Transaction not working properly",
    },
   
  ]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(3);
  const [filter, setFilter] = useState("");
  const [show, setShow] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  const filteredContact = contact.filter((contact) => {
    return contact.name.toLowerCase().includes(filter.toLowerCase());
  });

  const totalPages = Math.ceil(filteredContact.length / perPage);
  const indexOfLastItem = page * perPage;
  const indexOfFirstItem = indexOfLastItem - perPage;
  const currentContact = filteredContact.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    // setChangeImage(false);
  };
  const handleRefresh = () => {
    setRefreshKey((prevRefreshKey) => prevRefreshKey + 1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setPage(1);
  };

  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
    setPage(1);
  };

  useEffect(() => {}, [refreshKey]);

  return (
    <>
      <div className="pagetitle">
        <h1>Support</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/app/user/dashboard">Dashboard</NavLink>
            </li>
            <li className="breadcrumb-item">
              <NavLink to="/app/user/order">Order</NavLink>
            </li>
            <li className="breadcrumb-item active">Support</li>
          </ol>
        </nav>
      </div>

      <section className="section contact">
        <div className="row">
          <div className="col-lg-12 col-md-12 order-2 order-sm-1 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
              <div className="d-flex justify-content-between">
                  <div>
                    <h4 className="card-title d-flex align-items-center">
                    Support
                    </h4>
                  </div>
                </div>

                {/* <!-- Start Table --> */}
                <div className="scroll-area-lg">
                  <div className="table-responsive-sm">
                    {/* Filter input */}
                    <div className="filter-container d-flex justify-right-end mb-2">
                      <input
                        type="text"
                        placeholder="Search by brand name..."
                        value={filter}
                        onChange={handleFilterChange}
                      />
                    </div>

                    <table className="table table-striped table-borderless table-hover ">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Subject</th>
                          <th scope="col" width="10%"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentContact.map((contact) => (
                          <tr key={contact.id}>
                            <th scope="row">{contact.id}</th>
                            <td>{contact.name}</td>
                            <td>{contact.email}</td>
                            <td>{contact.subject}</td>
                            <td className="dropdown">
                              <Link
                                className="dropdown-toggle menu"
                                id={`actionDropdown_${contact.id}`}
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="mdi mdi-apps"></i>
                              </Link>
                              <div
                                className="dropdown-menu dropdown-menu-right preview-list"
                                aria-labelledby={`actionDropdown_${contact.id}`}
                              >
                                <Link
                                  className="dropdown-item custom-drop"
                                  onClick={() => handleShow()}
                                >
                                  <i className="mdi mdi-pencil mr-2 text-success"></i>{" "}
                                  <span>View</span>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-2">
                  <div className="d-flex">
                    Per page:
                    <select
                      className="ml-1"
                      name="perPage"
                      onChange={handlePerPageChange}
                      value={perPage}
                    >
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="5">5</option>
                      <option value="20">20</option>
                    </select>
                  </div>

                  <div className="pagination">
                    <Stack spacing={2}>
                      <Pagination
                        sx={{ color: "#09b294" }}
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                      />
                    </Stack>
                  </div>
                </div>
                {/* <!-- End Table --> */}
              </div>
            </div>
          </div>
        </div>

        <ViewAdminSupport
           show={show}
           closeModal={handleClose}
           refreshPage={handleRefresh}
        />
      </section>
    </>
  );
}
