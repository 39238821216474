import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import "./CreateVehicle.css";
import axiosInstance from "../../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import Loader from "../../../Utilities/Loader/Loader";
import { Link, useNavigate } from "react-router-dom";

export default function DeliveryVehicle({ show, closeModal, currentOrder }) {
    const navigate = useNavigate()

  const [validationError, setValidationError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentVehicle, setCurrentVehicle] = useState(null)
  const [vehicles, setVehicles] = useState([])
  const [formData, setFormData] = useState({
    vehicle_id: ""
  });

  const resetForm = () => {
    setFormData({
      vehicle_id: ""
    });
    setValidationError({});
  };

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
    const filteredVehicle = vehicles.filter(vehicle => vehicle.id == e.target.value)
    setCurrentVehicle(filteredVehicle[0]);
  };

  const handleClose = () => {
    resetForm();
    closeModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    if(!currentVehicle.quantity) {
      toast.error("Vehicle Gas quantity not set")
      return
    }
    if(Number(currentOrder.quantity) > Number(currentVehicle.quantity)) {
      toast.error("Vehicle gas capacity cannot complete order")
      return
    }
    setIsLoading(true)
    axiosInstance.post(`/user/order/supplier/accept/${currentOrder.id}`, formData)
        .then(res => {
            toast.success("Order accepted successfully")
            navigate(`/app/user/order/details/${currentOrder.id}`)
        })
        .catch(err =>  console.error(err.response))
        .finally(() => setIsLoading(false))
  };

  const fetchSupplierVehicles = () => {
    setIsLoading(true)
    axiosInstance.get(`/user/supplier/vehicle/all`)
        .then(res => {
            setVehicles(res.data.data.items)
        })
        .catch(err =>  console.error(err.response))
        .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    fetchSupplierVehicles()
    setFormData({
        ...currentOrder,
        vehicle_id: ''
    })
  }, [currentOrder])

  return (
    <div className="CreateVehicle">
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Select Vehicle for <span className="text-success">
                {currentOrder.quantity}kg
                </span> gas order
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {currentVehicle == '' ?
              <div className="alert alert-danger">
                <h4>No vehicle created yet</h4>
                <Link to="/app/supplier/vehicles" className="btn btn-success">Create Vehicle</Link>
              </div>
            :
              <div className="alert alert-warning">
                <div className="d-flex justify-content-between">
                  <div>
                      <p className="mb-1">Capacity</p>
                      <h4>{currentVehicle?.capacity} kg</h4>
                  </div>
                  <div>
                      <p className="mb-1">Current Quantity</p>
                      <h4>{currentVehicle && currentVehicle.quantity ? currentVehicle.quantity : '-'} kg</h4>
                  </div>
                </div>
              </div>
            }
            {/* <Form.Label>Name</Form.Label> */}
            <Form.Group className="mb-3" controlId="formBasicname">
              <div>
                <h6>Vehicle</h6>
              </div>
              <select 
                className="form-control custom-form-control"
                type="text"
                placeholder=""
                name="vehicle_id"
                value={formData.vehicle_id}
                onChange={handleChange}
                required
              >
                <option value="">--Select--</option>
                {vehicles.length > 0 &&  vehicles.map(vehicle => (
                  <option value={vehicle.id} key={vehicle.id}>
                    {vehicle.year_manufactured} - {vehicle.brand_name} {vehicle.model}
                  </option>
                ))}
              </select>
              {validationError.vehicle_id && (
                <small className="text-danger my-1">
                  {validationError.vehicle_id[0]}
                </small>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={handleClose}>
              Close
            </Button>
            <Button className="custom-primary" type="submit" disabled={!formData.vehicle_id}>
              Accept Order
            </Button>
          </Modal.Footer>
        </Form>
        {isLoading && <Loader />}
      </Modal>
    </div>
  );
}
