import React from 'react';
import { Modal } from 'react-bootstrap';
import Map from './Map';

const ViewMap = ({customerLocation, supplierLocation, show, closeModal, }) => {
    const handleClose = () => {
        closeModal();
    };

    // Return a div element to hold the map
    return (
        <>
            <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Body>
                <div className="d-flex justify-content-end">
                    <i
                    onClick={handleClose}
                    className="mdi mdi-close-circle text-muted"
                    style={{ fontSize: "25px", cursor: "pointer" }}
                    ></i>
                </div>
                <Map 
                    customerLocation={customerLocation}
                    supplierLocation={supplierLocation}
                />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ViewMap;