import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "./AddressMap.css";
import H from '@here/maps-api-for-javascript';
import axios from 'axios';
import useAuth from "../../../hooks/useAuthContext";
import nearbySupplierService from "../../../services/nearbySuppliers";
import { toast } from "react-toastify";

export default function AddressMap({
  show,
  closeModal,
  setInputAddress,
  apikey,
  setCoordinates,
  setInputValue
}) {

  const mapRef = useRef(null);
  const map = useRef(null);
  const platform = useRef(null);
  const [userCoordinates, setUserCoordinates] = useState(null)
  const [address, setAddress] = useState(null);
  const {userData} = useAuth();
  const [isDeliverable, setIsDeliverable] = useState(true)

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }

    function success(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      setUserCoordinates({ lat: latitude, lng: longitude })
      getAddress(position.coords);
    }

    function error() {
      console.log("Unable to retrieve your location");
    }
  };

  const getMarkerIcon = (url) => {
    return new H.map.Icon(url);
  }

  const addMarker = (position, map) => {
    const marker = new H.map.Marker(position, {
        icon: getMarkerIcon('https://img.icons8.com/external-smashingstocks-isometric-smashing-stocks/55/external-Cylinder-travel-and-outdoor-smashingstocks-isometric-smashing-stocks.png'),
        // Enable smooth dragging
        volatility: true
    });
    // Enable draggable markers
    marker.draggable = true;

    map.addObject(marker);
    return marker;
  }

  const getAddress = async(position) => {
    if(position.lat && position.lng) {
      const response =  await axios.get(`https://revgeocode.search.hereapi.com/v1/revgeocode?apiKey=${apikey}&at=${position?.lat},${position?.lng}&lang=en-US`)
    //    const response = await axios.get(`${AUTOCOMPLETE_API_URL}?q=${value}&apiKey=${apiKey}`);
      setAddress(response.data.items[0].address.label);
    }
  }

  const confirmAddress = async () => {
    await setInputAddress(address);
    await setCoordinates(userCoordinates)
    await setInputValue(address)
    handleClose();
  };

  const handleClose = () => {
    closeModal();
  };

  useEffect(() => {
    getUserLocation();
  }, []);

  const checkSuppliers = async(coordinates) => {
    const suppliers = await nearbySupplierService.fetchNearbySuppliers(coordinates)
    if(suppliers.length == 0) {
      toast.error("Out of delivery area")
      setIsDeliverable(false)
    }  else {
      setIsDeliverable(true)
    }
  }

  useEffect(() => {
    if(userCoordinates) {
      getAddress(userCoordinates)
    }
    if(userData.userInfo && userData.userInfo.user_type == 'user' && userCoordinates){
      checkSuppliers(userCoordinates)
    }
  }, [userCoordinates])

  useEffect(() => {
    // Check if the map object has already been created
    if (!map.current && userCoordinates) {
        // Create a platform object with the API key and useCIT option
        platform.current = new H.service.Platform({
            apikey
        });
        // Obtain the default map types from the platform object:
        const defaultLayers = platform.current.createDefaultLayers({
            pois: true
        });
        // Create a new map instance with the Tile layer, center and zoom level
        // Instantiate (and display) a map:
        const newMap = new H.Map(
            mapRef.current,
            defaultLayers.vector.normal.map, {
                zoom: 15,
                center: userCoordinates,
            }
        );

        // Add panning and zooming behavior to the map
        const behavior = new H.mapevents.Behavior(
            new H.mapevents.MapEvents(newMap)
        );

        // Enable dynamic resizing of the map, based on the current size of the enclosing cntainer
        window.addEventListener('resize', () => newMap.getViewPort().resize());

        /**
         * Listen to the dragstart and store the relevant position information of the marker
         */
        newMap.addEventListener('dragstart', function(ev) {
          const target = ev.target;
          const pointer = ev.currentPointer;
          if (target instanceof H.map.Marker) {
              // Disable the default draggability of the underlying map
              behavior.disable(H.mapevents.Behavior.Feature.PANNING);

              var targetPosition = newMap.geoToScreen(target.getGeometry());
              // Calculate the offset between mouse and target's position
              // when starting to drag a marker object
              target['offset'] = new H.math.Point(
                  pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
          }
        }, false);

        /**
         * Listen to the drag event and move the position of the marker as necessary
         */
        newMap.addEventListener('drag', function(ev) {
          const target = ev.target;
          const pointer = ev.currentPointer;
          if (target instanceof H.map.Marker) {
              target.setGeometry(
                  newMap.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y)
              );
          }
        }, false);

        /**
         * Listen to the dragend and update the route
         */
        newMap.addEventListener('dragend', function(ev) {
          const target = ev.target;
          if (target instanceof H.map.Marker) {
              // re-enable the default draggability of the underlying map
              // when dragging has completed
              behavior.enable(H.mapevents.Behavior.Feature.PANNING);
              const coords = target.getGeometry();
              // const markerId = target.getData().id;

              // Add this line to center the map on the marker during drag:
              newMap.setCenter(target.getGeometry());

              setUserCoordinates({ lat: coords.lat, lng: coords.lng })
              // console.log(coords.lat);

              // Update the routing params `origin` and `destination` properties
              // in case we dragging either the origin or the destination marker
              // if (markerId === 'A') {
              //     routingParams.origin = `${coords.lat},${coords.lng}`;
              // } else if (markerId === 'B') {
              //     routingParams.destination = `${coords.lat},${coords.lng}`;
              // }

              // updateRoute();
          }
        }, false);

        // Set the map object to the reference
        map.current = newMap;


        const ui = H.ui.UI.createDefault(newMap, defaultLayers)

        if(userCoordinates) {
          addMarker(userCoordinates, map.current)
        }
      }
  }, [userCoordinates, apikey]);
  return (
    <>
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Body>
          <div className="d-flex justify-content-end">
            <i
              onClick={handleClose}
              className="mdi mdi-close-circle text-muted"
              style={{ fontSize: "25px", cursor: "pointer" }}
            ></i>
          </div>
          <div className="map-info-wrapper">
            <div className="text-center">
              <h4>Pin address on map</h4>
            </div>
            <div className="map-wrapper">
              <div style={{ width: "100%", height: "100%" }} ref={mapRef} />
            </div>
            {address && (
              <div className="address-info d-flex align-items-center">
                <span style={{ marginRight: "10px" }}>
                  <img
                    width="40"
                    src="https://img.icons8.com/external-smashingstocks-isometric-smashing-stocks/55/external-Cylinder-travel-and-outdoor-smashingstocks-isometric-smashing-stocks.png"
                    alt="external-Cylinder-travel-and-outdoor-smashingstocks-isometric-smashing-stocks"
                  />
                </span>
                {address}
              </div>
            )}
            <div className="confirm-button text-center">
              <button
                className="btn btn-primary rounded-lg"
                onClick={confirmAddress}
                disabled={!address || !isDeliverable}
              >
                Confirm Address
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
