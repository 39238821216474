// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { Card } from "react-bootstrap";

import "./Supplier/Vehicle/CreateVehicle.css";
// import axios from 'axios';

export default function ViewAdminVehicle({
  show,
  closeModal,
  refreshPage,
}) {
  const handleClose = () => {
    closeModal();
  };

  //   const handleClose = () => {
  //     resetForm();
  //     closeModal();
  //   }

  const [contact, setContact] = useState([
    {
      id: 2,
      name: "Jacob James",
      email: "client@client.com",
      subject: "Transaction not working well",
      message: "Please kindly rectify my constant failed transaction. Thank you"
    },
  ]);

  const contactCards = contact.map((contactItem) => (
    <Card key={contactItem.id} className="mb-3">
      <Card.Body>
        <Card.Title>Support Details</Card.Title>
        <Card.Text>
          <strong>Name:</strong>{" "}
          {contactItem.name}
          <br />
          <strong>Email:</strong> {contactItem.email}
          <br />
          <strong>Subject:</strong> {contactItem.subject}
          <br />
          <strong>Message:</strong> {contactItem.message}
          <br />
        </Card.Text>
      </Card.Body>
    </Card>
  ));

  return (
    <div className="ViewContact">
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>View Support Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>{contactCards}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={refreshPage}>
            Refresh
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
