import React from 'react'
import "./Profile.css"
import { NavLink } from 'react-router-dom'
import useAuth from '../../hooks/useAuthContext'
import EditProfile from '../../components/Profile/EditProfile'
import ChangePassword from '../../components/Profile/ChangePassword'

const Profile = () => {
    const {userData} = useAuth()
  return (
    <>
      <div className="pagetitle">
        <h1>Profile</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/app/user/dashboard">Dashboard</NavLink>
            </li>
            <li className="breadcrumb-item active">Profile</li>
          </ol>
        </nav>
      </div>

      <section className="section profile">
      <div className="row">
        <div className="col-xl-4">

          <div className="card">
            <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">

              <img src="/assets/img/profile-img.jpg" alt="Profile" className="rounded-circle" />
              <h2 style={{lineHeight: 1.5}}>{userData?.userInfo?.last_name} {userData?.userInfo?.first_name}</h2>
              <h3 className='text-capitalize'>{userData?.userInfo?.user_type}</h3>
              <div className="social-links mt-2">
                {/* <a href="#" className="twitter"><i className="bi bi-twitter"></i></a>
                <a href="#" className="facebook"><i className="bi bi-facebook"></i></a>
                <a href="#" className="instagram"><i className="bi bi-instagram"></i></a>
                <a href="#" className="linkedin"><i className="bi bi-linkedin"></i></a> */}
              </div>
            </div>
          </div>

        </div>

        <div className="col-xl-8">

          <div className="card">
            <div className="card-body pt-3">
              {/* <!-- Bordered Tabs --> */}
              <ul className="nav nav-tabs nav-tabs-bordered">

                <li className="nav-item">
                  <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview">Overview</button>
                </li>

                <li className="nav-item">
                  <button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit">Edit Profile</button>
                </li>

                <li className="nav-item">
                  <button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-change-password">Change Password</button>
                </li>

              </ul>
              <div className="tab-content pt-2">

                <div className="tab-pane fade show active profile-overview" id="profile-overview">
                  <h5 className="card-title">Profile Details</h5>

                  <div className="row">
                    <div className="col-lg-3 col-md-4 label">Email</div>
                    <div className="col-lg-9 col-md-8">{userData?.userInfo?.email}</div>
                  </div>

                  <div className="row">
                    <div className="col-lg-3 col-md-4 label ">Full Name</div>
                    <div className="col-lg-9 col-md-8">{userData?.userInfo?.last_name} {userData?.userInfo?.first_name}</div>
                  </div>

                  {userData?.userInfo?.user_type == 'supplier' && (
                    <div className="row">
                        <div className="col-lg-3 col-md-4 label">Company</div>
                        <div className="col-lg-9 col-md-8">{userData?.userInfo?.company_name}</div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-lg-3 col-md-4 label">Address</div>
                    <div className="col-lg-9 col-md-8">{userData?.userInfo?.address}</div>
                  </div>

                  <div className="row">
                    <div className="col-lg-3 col-md-4 label">Phone</div>
                    <div className="col-lg-9 col-md-8">{userData?.userInfo?.phone}</div>
                  </div>

                </div>

                <div className="tab-pane fade profile-edit pt-3" id="profile-edit">
                    <EditProfile personalDetail={userData?.userInfo} />
                </div>

                <div className="tab-pane fade pt-3" id="profile-change-password">
                  <ChangePassword />
                </div>

              </div>
              {/* <!-- End Bordered Tabs --> */}

            </div>
          </div>

        </div>
      </div>
    </section>
    </>
  )
}

export default Profile
