import React, { useState } from "react";
import { Link, NavLink, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../hooks/axiosInstance";
import "./Login.css"
import ReactPasswordChecklist from "react-password-checklist";

export default function ResetPassword() {
  const navigate = useNavigate()

    const [queryParameters] = useSearchParams()

    const [loading, setLoading] = useState(false)
    const [userData, setUserData] = useState({
        email: localStorage.getItem('resetEmail'),
        password: "",
        password_confirmation: "",
        token: queryParameters.get("token")
    });
    const [validationError, setValidationError] = useState({})
    const { password, password_confirmation } = userData

    const [toggle, setToggle] = useState(false)
    const [toggleConfirm, setToggleConfirm] = useState(false)


    const handleToggle = () => {
        setToggle(!toggle)
    }

    const handleConfirmToggle = () => {
        setToggleConfirm(!toggleConfirm)
    }

    const handleChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };


    const handleSubmit = (e) => {
        e.preventDefault()
        if (password === "" || password_confirmation === "") {
            toast.error("Pls enter all required!")
        } else if (password !== password_confirmation) {
            toast.error("Password not match")
        } else {
            setLoading(true)
            setValidationError({})
            axiosInstance.post('/user/auth/password/reset', userData)
                .then(res => {
                    toast.success(res.data.message)
                    localStorage.removeItem('resetEmail')
                    navigate('/auth/login')
                })
                .catch(err => {
                    toast.error("An error occured. Please, try again")
                    if (err.response.status == 422) {
                        setValidationError(err.response.data.errors)
                    }
                })
                .finally(() => setLoading(false))
        }
    }


  return (
    <div>
      <main style={{ backgroundColor: "#f6f9ff", height: "100vh" }}>
        <div className="container">
          <section className="section register d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-6 d-flex flex-column align-items-center justify-content-center">
                  <div className="d-flex justify-content-center py-4">
                    <Link
                      to="/"
                      className="logo d-flex align-items-center w-auto"
                    >
                      <img src="/assets/images/logo-222x66.png" alt="" />
                      {/* <span className="d-none d-lg-block">NiceAdmin</span> */}
                    </Link>
                  </div>

                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="pt-4 pb-2">
                        <h5 className="card-title text-center pb-0 fs-4">
                          Create new password
                        </h5>
                        <p
                          className="text-center mt-0"
                          style={{ lineHeight: 1.2 }}
                        >
                          Your new password must be different from previously used password.
                        </p>
                      </div>
                      <form className="row mt-0 g-3" onSubmit={handleSubmit}>
                        <div className="col-12">
                          <label for="yourPassword" className="app-form-label">
                            Password
                          </label>
                          <input
                            type="password"
                            name="password"
                            className="form-control"
                            id="yourPassword"
                            value={password}
                            onChange={handleChange}
                            required
                          />
                          {password && (
                              <ReactPasswordChecklist
                                  className="p-3 bg-light mb-2 mt-2 rounded"
                                  rules={[
                                      "minLength",
                                      "specialChar",
                                      "number",
                                      "capital",
                                      "match",
                                  ]}
                                  minLength={8}
                                  value={password}
                                  valueAgain={password_confirmation}
                              />
                          )}
                        </div>

                        <div className="col-12">
                          <label for="confirmPassword" className="app-form-label">
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            name="password_confirmation"
                            className="form-control"
                            id="confirmPassword"
                            value={password_confirmation}
                            onChange={handleChange}
                            required
                          />
                        </div>

                        <div className="col-12">
                          <button
                            className="btn btn-primary w-100 my-3"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? <div class="spinner-border spinner-border-sm text-light"></div> : 'Reset Password'}
                          </button>
                        </div>
                        <div className="col-12 text-center">
                          <p className="small mb-3">
                            Remember  password?{" "}
                            <NavLink to="/auth/login">
                              Back to Login
                            </NavLink>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
}
