// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import axiosInstance from "../../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import Loader from "../../../Utilities/Loader/Loader";
// import axios from 'axios';

export default function EditBankAccount({ show, closeModal, currentBankAccount, refreshPage }) {
  const [validationError, setValidationError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [paystackBanks, setPayStackBanks] =  useState([])
  const [formData, setFormData] = useState({
    account_name: "",
    bank: "",
    account_number: ""
  });

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleClose = () => {
    closeModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidationError({})
    setIsLoading(true);
    axiosInstance
      .put(`/user/bank_account/${currentBankAccount?.id}`, formData)
      .then((response) => {
        toast.success(response.data.data.message);
        refreshPage();
        handleClose();
      })
      .catch((err) => {
        if (err.response.status == 422) {
          setValidationError(err.response.data.errors);
        }
        if(err.response.status == 402){
          toast.error("Could not resolve account!!!")
          return
        } 
        toast.error("An error occured. Please, try again");
        console.log(err.response.data);
      })
      .finally(() => setIsLoading(false));
  };

  const fetchPaystackbanks = () => {
    setIsLoading(true);
    axiosInstance
      .get(`/user/bank_account/banks`)
      .then((response) => {
        setPayStackBanks(response.data.data)
      })
      .catch((err) => {
        console.log(err.response.data);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    setFormData({
      'account_name': currentBankAccount?.account_name,
      'account_number': currentBankAccount?.account_number,
      'bank': `${currentBankAccount?.bank_code}, ${currentBankAccount?.bank_name}`,
      'status': currentBankAccount?.status
    })
  }, [currentBankAccount])

  useEffect(() => {
    fetchPaystackbanks()
  }, [])

  return (
    <div className="CreateVehicle">
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Edit <span className='text-success'>{currentBankAccount?.account_name}</span>  account tetails</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Form.Label>Name</Form.Label> */}
            <Form.Group className="mb-3" controlId="formBasicname">
              <div>
                <h6>Bank</h6>
              </div>
              <Form.Control 
                as="select"
                name="bank"
                value={formData.bank}
                onChange={handleChange}
                required
              >
                <option value="">--Select--</option>
                {paystackBanks && paystackBanks.map(bank => (
                  <option value={`${bank.code}, ${bank.name}`} key={bank.id}>{bank.name}</option>
                ))}
              </Form.Control>
              {validationError.bank && (
                <small className="text-danger my-1">
                  {validationError.bank[0]}
                </small>
              )}
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              {/* <Form.Label>Description</Form.Label> */}
              <div>
                <h6>Account Name</h6>
              </div>
              <Form.Control
                type="text"
                placeholder=""
                name="account_name"
                value={formData.account_name}
                onChange={handleChange}
                required
              />
              {validationError.account_name && (
                <small className="text-danger my-1">
                  {validationError.account_name[0]}
                </small>
              )}
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              {/* <Form.Label>Description</Form.Label> */}
              <div>
                <h6>Account Number</h6>
              </div>
              <Form.Control
                type="text"
                placeholder=""
                name="account_number"
                value={formData.account_number}
                onChange={handleChange}
                required
              />
              {validationError.account_number && (
                <small className="text-danger my-1">
                  {validationError.account_number[0]}
                </small>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicname">
              <div>
                <h6>Status</h6>
              </div>
              <Form.Control 
                as="select"
                name="status"
                value={formData.status}
                onChange={handleChange}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Control>
              {validationError.bank && (
                <small className="text-danger my-1">
                  {validationError.bank[0]}
                </small>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={handleClose}>
              Close
            </Button>
            <Button className="custom-primary" type="submit">
              Update
            </Button>
          </Modal.Footer>
        </Form>
        {isLoading && <Loader />}
      </Modal>
    </div>
  );
}
