import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../hooks/axiosInstance";

const ForgotPassword = () => {
  const callbackUrl =
    process.env.NODE_ENV !== "production"
      ? process.env.REACT_APP_DEV_CALL_BACK_URL
      : process.env.REACT_APP_PROD_CALL_BACK_URL;

  const [resetData, setResetdata] = useState({
    email: "",
    callbackUrl: `${callbackUrl}/auth/reset-password`,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setResetdata({ ...resetData, email: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (resetData.email === "") {
      toast.error("Pls enter your email address");
      return;
    }
    setLoading(true);
    axiosInstance
      .post(`/user/auth/password/forgot`, resetData)
      .then((res) => {
        toast.success(res.data.message);
        localStorage.setItem("resetEmail", resetData.email);
      })
      .catch((err) => {
        console.log(err.response.data);
        toast.error(err.response.data.message);
      })
      .finally(() => setLoading(false));
  };
  return (
    <div>
      <main style={{ backgroundColor: "#f6f9ff", height: "100vh" }}>
        <div className="container">
          <section className="section register d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-6 d-flex flex-column align-items-center justify-content-center">
                  <div className="d-flex justify-content-center py-4">
                    <Link
                      to="/"
                      className="logo d-flex align-items-center w-auto"
                    >
                      <img src="/assets/images/logo-222x66.png" alt="" />
                      {/* <span className="d-none d-lg-block">NiceAdmin</span> */}
                    </Link>
                  </div>

                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="pt-4 pb-2">
                        <h5 className="card-title text-center pb-0 fs-4">
                          Password  Reset
                        </h5>
                        <p
                          className="text-center mt-0"
                          style={{ lineHeight: 1.2 }}
                        >
                          Enter your email and instructions will be sent to you!
                        </p>
                      </div>
                      <form className="row mt-0 g-3" onSubmit={handleSubmit}>
                        <div className="col-12">
                          <label for="yourPassword" className="app-form-label">
                            Email
                          </label>
                          <input
                            className="form-control"
                            id="yourPassword"
                            type="email"
                            name='email'
                            value={resetData.email}
                            onChange={handleChange}
                            required
                          />
                        </div>

                        <div className="col-12">
                          <button
                            className="btn btn-primary w-100 my-3"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? <div className="spinner-border spinner-border-sm text-light"></div> : 'Send Reset Link'}
                          </button>
                        </div>
                        <div className="col-12 text-center">
                          <p className="small mb-3">
                            Remember  password?{" "}
                            <NavLink to="/auth/login">
                              Back to Login
                            </NavLink>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};

export default ForgotPassword;
