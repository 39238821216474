// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";

import "./Supplier/Vehicle/CreateVehicle.css";
import useAuth from "../../hooks/useAuthContext";
import Loader from "../Utilities/Loader/Loader";
import axiosInstance from "../../hooks/axiosInstance";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
// import axios from 'axios';

export default function ViewSupplierAccounts({ show, closeModal, currentOrder, totalAmount}) {
  const {userData} =useAuth()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [bankAccounts, setBankAccounts]  = useState([])

  const handleClose = () => {
    closeModal();
  };

  const fetchSupplierBanks = () => {
    setLoading(true);
    axiosInstance
      .get(
        `/user/bank_account/supplier/${currentOrder?.supplier?.id}`
      )
      .then((response) => {
        setBankAccounts(response.data.data.items);
      })
      .catch((err) => {
        toast.error("An error occured. Please, try again");
      })
      .finally(() => setLoading(false));
  }

  const confirmTransfer = () => {
    setLoading(true);
    axiosInstance
      .post(
        `/user/order/cash_payment`, {
            order_id: currentOrder.id,
            payment_type: 'cash',
            amount: totalAmount
        }
      )
      .then((response) => {
        navigate("/app/user/history")
      })
      .catch((err) => {
        toast.error("An error occured. Please, try again");
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchSupplierBanks()
  }, [currentOrder])

  return (
    <div className="ViewTransaction">
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Supplier Bank Accounts</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {bankAccounts && bankAccounts.length > 0 ?
                    <>
                        {bankAccounts.map(account => (
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title>{account?.bank_name}</Card.Title>
                                    <Card.Text>
                                        <div className="d-flex justify-content-between">
                                            <p><b>Account Name</b></p>
                                            <p>{account?.account_name}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p><b>Account Number</b></p>
                                            <p>{account?.account_number}</p>
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                            </Card> 
                        ))}
                    </>
                :
                    <div className="alert alert-info">
                        <h4>No bank account found for supplier</h4>
                    </div>
                }  
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={handleClose}>
                    Close
                </Button>
                <Button className="custom-primary" onClick={confirmTransfer} disabled={bankAccounts.length == 0}>
                    I have made a Transfer
                </Button>
            </Modal.Footer>
        </Modal>
        {loading && <Loader />}
    </div>
  );
}
