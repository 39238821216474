// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import { Card } from "react-bootstrap";

import "./Supplier/Vehicle/CreateVehicle.css";
import useAuth from "../../hooks/useAuthContext";
// import axios from 'axios';

export default function ViewHistory({ show, closeModal, currentHistory, refreshPage }) {
  const {userData} =useAuth()

  const handleClose = () => {
    closeModal();
  };

  return (
    <div className="ViewTransaction">
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>View Order Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-3">
          <Card.Body>
            <Card.Title>Order Info</Card.Title>
            <Card.Text>
              <strong>Order Number:</strong> {currentHistory?.order_number}
              <br />
              <strong>Order Amount:</strong> ₦{Number(currentHistory?.order_amount).toLocaleString()}
              <br />
              <strong>Address:</strong> {currentHistory?.delivery_address}
              <br />
              <strong>Delivery:</strong> ₦{Number(currentHistory?.delivery_price).toLocaleString()}
              <br />
              <strong>Priority:</strong> {currentHistory?.priority}
              <br />
              <strong>Status:</strong> <span className={`${currentHistory?.status == 'delivered' ? 
                'bg-success' : currentHistory?.status == 'warning' ? 'bg-warning' : 'bg-danger'} badge text-capitalize`}>
                {currentHistory?.status}
              </span>
              <br />
              <strong>Date:</strong> {currentHistory?.created_at}
            </Card.Text>
          </Card.Body>
        </Card>  
        <Card className="mb-3">
          {userData?.userInfo?.user_type == 'user' ?
            <Card.Body>
              <Card.Title>Supplier Info</Card.Title>
              <Card.Text>
                <strong>Fullname:</strong> {currentHistory?.supplier?.last_name} {currentHistory?.supplier?.first_name}
                <br />
                <strong>Company Name:</strong> {currentHistory?.supplier?.company_name}
                <br />
                <strong>Phone:</strong> {currentHistory?.supplier?.phone}
                <br />
                <strong>Email:</strong> {currentHistory?.supplier?.email}
                <br />
              </Card.Text>
            </Card.Body>
          :
            <Card.Body>
              <Card.Title>Customer Info</Card.Title>
              <Card.Text>
                <strong>Fullname:</strong> {currentHistory?.customer?.last_name} {currentHistory?.customer?.first_name}
                <br />
                <strong>Phone:</strong> {currentHistory?.customer?.phone}
                <br />
                <strong>Email:</strong> {currentHistory?.customer?.email}
                <br />
              </Card.Text>
            </Card.Body>
          }
        </Card>  
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
  );
}
