import React, { useState, useEffect, useRef } from 'react';
import AddressMap from "../Modal/AddressMap/AddressMap";
import axios from 'axios';
import './LocationInput.css'
import useAuth from '../../hooks/useAuthContext';
import nearbySupplierService from '../../services/nearbySuppliers';
import { toast } from 'react-toastify';

export default function LocationInput({
  formWrapClass,
  inputClass,
  name,
  setInputAddress,
  setInputCoordinates,
  locationClass,
  checkDeliverability,
  handleChange,
  formValue,
  required
}) {
  const {userData} = useAuth();

  const apiKey = process.env.REACT_APP_HERE_MAP_API_KEY

  const inputRef = useRef(null);
  const [currentLocLoading, setCurrentLocLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [checkCoordinates, setCheckCoordinates]  = useState(null)
  const [showMap, setShowMap] = useState(false);

  // const setMarker = async (data) => {
  //   await setMarkerPosition(data);
  //   getAddress(data.lat, data.lng);
  // };

  // Moved fetchSuggestions outside useEffect for broader scope (Option 1)
  const fetchSuggestions = async (value) => {
      if (!value) {
          setSuggestions([]);
          return;
      }
      const response =  await axios.get(`https://autosuggest.search.hereapi.com/v1/autosuggest?apiKey=${apiKey}&at=33.738045,73.084488&limit=5&resultType=city&q=${value}&lang=en-US`)
    //    const response = await axios.get(`${AUTOCOMPLETE_API_URL}?q=${value}&apiKey=${apiKey}`);
      setSuggestions(response.data.items);
  };

  const setAddress = (address) => {
    setInputAddress(address);
  };

  const setCoordinates = (coordinates)  => {
    setInputCoordinates(coordinates)
  }

  const closeModal = () => {
    setShowMap(false);
  };

  const getUserLocation = () => {
    if (navigator.geolocation) {
      setCurrentLocLoading(true);
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }

    function success(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setCoordinates({ lat: latitude, lng: longitude })
        setCheckCoordinates({ lat: latitude, lng: longitude })
        getAddress(latitude, longitude);
    }

    function error() {
        console.log("Unable to retrieve your location");
    }
};

const getAddress = async(latitude, longitude) => {
    const response =  await axios.get(`https://revgeocode.search.hereapi.com/v1/revgeocode?apiKey=${apiKey}&at=${latitude},${longitude}&lang=en-US`)
    setAddress(response.data.items[0].address.label);
    setInputValue(response.data.items[0].address.label)
    setCurrentLocLoading(false);
}

  useEffect(() => {
      const handleInputKeyDown = (event) => {
          // Handle keyboard interactions (optional)
      };

      inputRef.current?.addEventListener('keydown', handleInputKeyDown);

      return () => {
          inputRef.current?.removeEventListener('keydown', handleInputKeyDown);
      };
  }, [apiKey]);

  const handleInputChange = (event) => {
      // setInputValue(event.target.value);
      handleChange(event)
      fetchSuggestions(event.target.value); // Call fetchSuggestions here
  };

  const handleSuggestionClick = (suggestion) => {
      setInputValue(suggestion.address.label);
      setAddress(suggestion.address.label)
      setCoordinates(suggestion.position);
      setCheckCoordinates(suggestion.position);
      setSuggestions([]);
      // onSelect(suggestion); // Call callback with selected suggestion
  };

  useEffect(() =>  {
    if(userData.userInfo && userData.userInfo.user_type == 'user') {
      setInputValue(userData.userInfo.address)
      setCoordinates(userData.userInfo.location)
      setCheckCoordinates(userData.userInfo.location)
      setAddress(userData.userInfo.address)
    }
  }, [userData])

  const checkSuppliers = async(coordinates) => {
    const suppliers = await nearbySupplierService.fetchNearbySuppliers(coordinates)
    if(suppliers.length == 0) {
      toast.error("Out of delivery area")
      checkDeliverability(false)
    } else {
      checkDeliverability(true)
    }
  }

  useEffect(() => {
    if(userData.userInfo && userData.userInfo.user_type == 'user' && checkCoordinates){
      checkSuppliers(checkCoordinates)
    }
  }, [checkCoordinates])

  return (
    <>
      <>
        <div className={formWrapClass}>
          <div>
            <input
              ref={inputRef}
              className={inputClass}
              type="text"
              name={name}
              value={formValue}
              onChange={handleInputChange}
              placeholder="Delivery Address"
              required={required}
            />
            {/* <span className="form-icon mdi mdi-map-marker"></span> */}
          </div>
          {suggestions.length > 0 && (
                <div className='suggestion-wrapper'>
                    <ul className="suggestions-list">
                        {suggestions.map((suggestion) => (
                            <li key={suggestion.id} onClick={() => handleSuggestionClick(suggestion)}>
                                {suggestion?.address?.label}
                            </li>
                        ))}
                    </ul>
                </div>
           )}
          <div className={locationClass}>
            {currentLocLoading ? (
              <span>Fetching...</span>
            ) : (
              <span onClick={getUserLocation}>Use current location</span>
            )}{" "}
            |<span onClick={() => setShowMap(true)}>Choose from Map</span>
          </div>
        </div>

        {showMap &&  (<AddressMap
          show={showMap}
          closeModal={closeModal}
          apikey={apiKey}
          address={formValue}
          // setMarker={setMarker}
          // markerPosition={markerPosition}
          setInputAddress={setAddress}
          setCoordinates={setCoordinates}
          setInputValue={setInputValue}
          // isLoaded={isLoaded}
        />)}
      </>
    </>
  );
}
