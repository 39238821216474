import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import "./CreateVehicle.css";
import axiosInstance from "../../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import Loader from "../../../Utilities/Loader/Loader";

export default function RefillVehicle({ show, closeModal, refreshPage, currentVehicle }) {
  const [validationError, setValidationError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    quantity: ""
  });

  const resetForm = () => {
    setFormData({
      quantity: ""
    });
    setValidationError({});
  };

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleClose = () => {
    resetForm();
    closeModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let currentQuantity =  Number(formData.quantity) + Number(currentVehicle.quantity ? currentVehicle.quantity : 0)

    if(currentQuantity > currentVehicle?.capacity) {
        toast.error('Gas quantity cannot be more than Vehicle capacity')
        return
    }
    setIsLoading(true);
    axiosInstance
      .post(`/user/supplier/vehicle/refill/${currentVehicle?.id}`, formData)
      .then((response) => {
        toast.success(response.data.message);
        refreshPage();
        handleClose();
      })
      .catch((err) => {
        toast.error("An error occured. Please, try again");
        if (err.response.status == 422) {
          setValidationError(err.response.data.errors);
        }
        console.log(err.response.data);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="CreateVehicle">
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Refill Gas for <span className="text-success">
                {currentVehicle?.brand_name} {currentVehicle?.model}
                </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="alert alert-warning">
              <div className="d-flex justify-content-between">
                <div>
                    <p className="mb-1">Capacity</p>
                    <h4>{currentVehicle?.capacity} kg</h4>
                </div>
                <div>
                    <p className="mb-1">Current Quantity</p>
                    <h4>{currentVehicle.quantity ? currentVehicle.quantity : '-'} kg</h4>
                </div>
              </div>
            </div>
            {/* <Form.Label>Name</Form.Label> */}
            <Form.Group className="mb-3" controlId="formBasicname">
              <div>
                <h6>Quantity (kg)</h6>
              </div>
              <Form.Control
                type="text"
                placeholder=""
                name="quantity"
                value={formData.quantity}
                onChange={handleChange}
                required
              />
              {validationError.quantity && (
                <small className="text-danger my-1">
                  {validationError.quantity[0]}
                </small>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={handleClose}>
              Close
            </Button>
            <Button className="custom-primary" type="submit">
              Refill
            </Button>
          </Modal.Footer>
        </Form>
        {isLoading && <Loader />}
      </Modal>
    </div>
  );
}
