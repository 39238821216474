import React, { useState } from "react";
import { toast } from "react-toastify";
import axiosInstance from "../../hooks/axiosInstance";
import ReactPasswordChecklist from "react-password-checklist";
import Loader from "../Utilities/Loader/Loader";

const ChangePassword = () => {
  const [formData, setFormData] = useState({
    current_password: "",
    new_password: "",
    new_password_confirmation: "",
  });

  const [toggle, setToggle] = useState(false);
  const [toggleConfirm, setToggleConfirm] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [validationError, setValidationError] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleConfirmToggle = () => {
    setToggleConfirm(!toggleConfirm);
  };

  const resetFormData = () => {
    setFormData({
      current_password: "",
      new_password: "",
      new_password_confirmation: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.new_password !== formData.new_password_confirmation) {
      toast.error("Password do not match");
      return;
    }
    setSubmitLoading(true);
    setValidationError({});
    axiosInstance
      .post("/user/change-password", formData)
      .then((res) => {
        toast.success("Password updated Succesfully");
        resetFormData();
      })
      .catch((err) => {
        toast.error("An error occured. Please, try again");
        if (err.response.status == 422) {
          setValidationError(err.response.data.errors);
        }
      })
      .finally(() => setSubmitLoading(false));
  };
  return (
    <>
      {/* <!-- Change Password Form --> */}
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <label
            for="currentPassword"
            className="col-md-4 col-lg-3 col-form-label"
          >
            Current Password
          </label>
          <div className="col-md-8 col-lg-9">
            <input
              type="password"
              className="form-control"
              id="currentPassword"
              name="current_password"
              value={formData.current_password}
              onChange={handleChange}
            />
            {validationError.current_password && (
              <small className="text-danger mt-1 mb-0">
                {validationError.current_password[0]}
              </small>
            )}
          </div>
        </div>

        <div className="row mb-3">
          <label for="newPassword" className="col-md-4 col-lg-3 col-form-label">
            New Password
          </label>
          <div className="col-md-8 col-lg-9">
            <input
              type="password"
              className="form-control"
              id="newPassword"
              name="new_password"
              value={formData.new_password}
              onChange={handleChange}
            />
            {validationError.new_password && (
              <small className="text-danger mt-1 mb-0">
                {validationError.new_password[0]}
              </small>
            )}
            {formData.new_password && (
              <ReactPasswordChecklist
                className="p-3 bg-light mb-2 mt-2 rounded"
                rules={[
                  "minLength",
                  "specialChar",
                  "number",
                  "capital",
                  "match",
                ]}
                minLength={8}
                value={formData.new_password}
                valueAgain={formData.new_password_confirmation}
              />
            )}
          </div>
        </div>

        <div className="row mb-3">
          <label
            for="renewPassword"
            className="col-md-4 col-lg-3 col-form-label"
          >
            Re-enter New Password
          </label>
          <div className="col-md-8 col-lg-9">
            <input
              type="password"
              className="form-control"
              id="renewPassword"
              name="new_password_confirmation"
              value={formData.new_password_confirmation}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="text-right">
          <button type="submit" className="btn btn-primary">
            Change Password
          </button>
        </div>
        {submitLoading && <Loader />}
      </form>
      {/* <!-- End Change Password Form --> */}
    </>
  );
};

export default ChangePassword;
