import React, { useState } from "react";
import "./Invoice.css"
import axiosInstance from "../../../hooks/axiosInstance";
import { Dialog, DialogContent, DialogContentText } from "@mui/material";
import SuccessPage from "../SuccessPage/SuccessPage";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ViewSupplierAccounts from "../../Modal/ViewSupplierAccounts";

export default function Invoice({invoice}) {
  const navigate = useNavigate()
  const paystackKey =  process.env.NODE_ENV !== 'production' ? 
                          process.env.REACT_APP_DEV_PAYSTACK_KEY :
                              process.env.REACT_APP_PROD_PAYSTACK_KEY;

  const [loading, setLoading] = useState(false)
  const [cashLoading, setCashLoading] = useState(false)
  const [showAccounts, setShowAccounts] = useState(false)
  const [paymentSuccess,  setPaymentSuccess] = useState(false)

  const total_amount = Number(invoice?.order_payment?.amount) + Number(invoice?.delivery_charge?.amount) +
                      Number(invoice?.outstanding_balance)

  const createPaymentIntent = () => {
    setLoading(true)
    axiosInstance.post("/user/order/payment-intent", {
      order_id: invoice?.order_payment.order_id,
      payment_type: 'card',
      amount: total_amount
    })
    .then(res => {
      payWithPaystack(res.data.data.item.transaction_reference)
    })
    .catch(err => console.log(err.response))
    .finally(() => setLoading(false))
  }

  const payWithPaystack = (transRef) => {
      var handler = window.PaystackPop.setup({
        key: paystackKey, // Replace with your public key
        email: invoice?.user?.email,
        amount: total_amount * 100, // the amount value is multiplied by 100 to convert to the lowest currency unit
        currency: 'NGN', // Use GHS for Ghana Cedis or USD for US Dollars
        ref: transRef, // Replace with a reference you generated
        callback: function(response) {
          //this happens after the payment is completed successfully
          paymentCallback(response); 
        },
        onClose: function() {
          alert('Transaction was not completed, window closed.');
        },
      });
      handler.openIframe();
  }

  const paymentCallback = async(response) => {
      const reference = response.reference;
      axiosInstance.post(`/user/order/verify-payment`, {transRef: reference})
                .then(res  => {
                  if(res.data.success){
                    toast.success("Payment successful")
                    setPaymentSuccess(true)
                    showOrder()
                  }
                })
  }

  const confirmTransfer = (invoice, totalAmount) => {
    setCashLoading(true);
    axiosInstance
      .post(
        `/user/order/cash_payment`, {
            order_id: invoice?.order_payment?.order.id,
            payment_type: 'cash',
            amount: totalAmount
        }
      )
      .then((response) => {
        navigate("/app/user/history")
      })
      .catch((err) => {
        toast.error("An error occured. Please, try again");
      })
      .finally(() => setCashLoading(false));
  }

  const handleClose = () => {
    setShowAccounts(false)
  }

  const showOrder = ()  => {
    setTimeout(() => {
      setPaymentSuccess(false)
      navigate(`/app/user/history`);
    }, 2000);
  }

  return (
    <div>
      <div className="receipt-content">
        <div className="container bootstrap snippets bootdey">
          <div className="row">
            <div className="col-md-12">
              <div className="invoice-wrapper">
                <div className="intro">
                  Hi <strong>{invoice?.user?.last_name} {invoice?.user?.first_name}</strong>,
                  <br />
                  This is the invoice for a payment of <strong>
                    ₦{Number(invoice?.order_payment?.amount).toLocaleString()}
                  </strong>{" "}
                  for your gas refill
                </div>

                <div className="payment-info">
                  <div className="row">
                    <div className="col-sm-6">
                      <span>Order No.</span>
                      <strong>{invoice?.order_payment?.order?.order_number}</strong>
                    </div>
                    <div className="col-sm-6 text-right">
                      <span>Issue Date</span>
                      <strong>{invoice?.order_payment?.created_at}</strong>
                    </div>
                  </div>
                </div>

                <div className="payment-details">
                  <div className="row">
                    <div className="col-sm-6">
                      <span>Customer</span>
                      <strong>{invoice?.user?.last_name} {invoice?.user?.first_name}</strong>
                      <p>
                        {invoice?.user?.address}
                        <br />
                        <a href="#">
                          {invoice?.user?.email}
                        </a>
                      </p>
                    </div>
                    <div className="col-sm-6 text-right">
                      <span>Payment To</span>
                      <strong>{invoice?.order_payment?.order?.supplier.last_name} {invoice?.order_payment?.order?.supplier.first_name}</strong>
                      <p>
                        {invoice?.order_payment?.order?.supplier.address}
                        <br />
                        {invoice?.order_payment?.order?.supplier.phone}
                        <br />
                        <a href="#">{invoice?.order_payment?.order?.supplier.email}</a>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="line-items">
                  <div className="headers clearfix">
                    <div className="row">
                      <div className="col-4">Description</div>
                      <div className="col-3">Quantity (kg)</div>
                      <div className="col-5 text-right">Amount (₦)</div>
                    </div>
                  </div>
                  <div className="items">
                    <div className="row item my-0">
                      <div className="col-4 desc">Gas Refil</div>
                      <div className="col-3 qty">{invoice?.order_payment?.order?.quantity}</div>
                      <div className="col-5 amount text-right">{Number(invoice?.order_payment?.amount).toLocaleString()}</div>
                    </div>
                    {/* <div className="row item my-0">
                      <div className="col-4 desc">Bootstrap snippet</div>
                      <div className="col-3 qty">1</div>
                      <div className="col-5 amount text-right">$20.00</div>
                    </div>
                    <div className="row item my-0">
                      <div className="col-4 desc">Snippets on bootdey</div>
                      <div className="col-3 qty">2</div>
                      <div className="col-5 amount text-right">$18.00</div>
                    </div> */}
                  </div>
                  <div className="total text-right">
                    <div className="field">
                      Subtotal <span>₦{Number(invoice?.order_payment?.amount).toLocaleString()}</span>
                    </div>
                    <div className="field">
                      Delivery <span>₦{Number(invoice?.delivery_charge?.amount).toLocaleString()}</span>
                    </div>
                    <div className="field">
                      Outstanding {invoice  && invoice.outstanding_balance > 0  ?
                        <span>₦{Number(invoice?.outstanding_balance).toLocaleString()}</span>
                        :
                        <span>-</span>
                      }
                    </div>
                    <div className="field grand-total">
                      Total <span>₦{total_amount.toLocaleString()}</span>
                    </div>
                  </div>

                  {invoice?.order_payment?.order?.payment_status != 'approved' && <div className="print">
                    <button className="buttons online me-3" onClick={() => confirmTransfer(invoice, total_amount)}>
                      {cashLoading ? (
                        <div className="spinner-border spinner-border-sm text-light"></div>
                      ) : (
                        <>
                          <i className="bi bi-cash" style={{marginRight: '10px'}}></i>
                          Pay Cash
                        </>
                      )}
                    </button>
                    <button className="buttons cash me-3" onClick={() => setShowAccounts(true)}>
                        <i className="bi bi-cash" style={{marginRight: '10px'}}></i>
                        Bank Transfer
                    </button>
                    <button className="buttons online" onClick={() => createPaymentIntent()}>
                      {loading ? (
                        <div className="spinner-border spinner-border-sm text-light"></div>
                      ) : (
                        <>
                          <i className="bi bi-globe" style={{marginRight: '10px'}}></i>
                          Pay Online
                        </>
                      )}
                    </button>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
          open={paymentSuccess}
          // onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
      {/* <DialogTitle id="alert-dialog-title">
          Delete <b className="text-primary">{currentVehicle.brand_name} {currentVehicle.model}</b>{" "}
      </DialogTitle> */}
          <DialogContent>
              <DialogContentText id="alert-dialog-description">
              <SuccessPage 
                  heading={'Payment successful'}
                  subHeading={'Your payment was successful and recorded on our system'}
                  subHeading2={'Thank you!'}
              />
              </DialogContentText>
          </DialogContent>
      </Dialog>

      {invoice && <ViewSupplierAccounts
          show={showAccounts}
          totalAmount={total_amount}
          closeModal={handleClose}
          currentOrder={invoice?.order_payment?.order}
      />}
    </div>
  );
}
