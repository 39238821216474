import React, { useEffect, useState } from "react";
import Invoice from "../../../components/Utilities/Invoice/Invoice";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../hooks/axiosInstance";
import Loader from "../../../components/Utilities/Loader/Loader";
import Pusher from 'pusher-js';

export default function Bill() {
  const appKey =  process.env.NODE_ENV !== 'production' ? 
                    process.env.REACT_APP_DEV_PUSHER_KEY :
                      process.env.REACT_APP_PROD_PUSHER_KEY
                  
  const appCluster =  process.env.NODE_ENV !== 'production' ? 
                        process.env.REACT_APP_DEV_PUSHER_CLUSTER :
                          process.env.REACT_APP_PROD_PUSHER_CLUSTER

  const {orderId} = useParams()
  const [loading, setLoading] = useState(false)
  const [invoice, setInvoice]  = useState([null])
  const navigate  = useNavigate()
 
  const fetchOrderInvoice = () => {
    setLoading(true)
    axiosInstance.get(`/user/order/invoice/${orderId}`)
        .then(res => {
            setInvoice(res.data.data)
        })
        .catch(err  => console.error(err))
        .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchOrderInvoice()
  }, [orderId])

  useEffect(() => {
    const pusher = new Pusher(appKey, {
      cluster: appCluster, // If applicable
    });

    const supplierConfirmChannel = pusher.subscribe('supplier-accept-order'); // Subscribe to your custom channel
    const customerConfirmChannel = pusher.subscribe('customer-accept-order'); // Subscribe to your custom channel

    supplierConfirmChannel.bind('order.supplier.accept', (data) => { // Listen for the custom event
      navigate('/app/user/history')
    });

    customerConfirmChannel.bind('order.customer.accept', (data) => { // Listen for the custom event
      navigate('/app/user/history')
    });

    return () => {
      pusher.disconnect(); // Disconnect on component unmount
    };
  }, []);

  return (
    <>
      <div className="pagetitle">
        <h1>Payment Invoice</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/app/user/dashboard">Dashboard</NavLink>
            </li>
            <li className="breadcrumb-item">
              <NavLink to="/app/user/order">Order</NavLink>
            </li>
            <li className="breadcrumb-item">
              <NavLink to="/app/user/order/details">Details</NavLink>
            </li>
            <li className="breadcrumb-item active">Bill</li>
          </ol>
        </nav>
      </div>
      {/* <!-- End Page Title --> */}

      <section className="section">
        {loading ? 
          <Loader />
        :
          <div className="row">
            <div className="col-lg-12">
              <Invoice invoice={invoice}/>
            </div>
          </div>
        }
      </section>
    </>
  );
}
