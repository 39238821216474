// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from 'react';
// import './CreateVehicle.css';
import axiosInstance from '../../../../hooks/axiosInstance';
import { toast } from 'react-toastify';
import Loader from '../../../Utilities/Loader/Loader';
import LocationInput from '../../../FormComponents/LocationInput';
// import axios from 'axios';

export default function EditUser({ show, currentUser, closeModal, refreshPage }) {
  const [validationError, setValidationError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDeliverable, setIsDeliverable] = useState(true)
  const [formData, setFormData] = useState({
    email: "",
    company_name: "",
    first_name: "",
    last_name: "",
    phone: "",
    address: "",
    coordinates: "",
  });

  const checkDeliverability = (status) => {
    setIsDeliverable(status)
  }

  const setInputAddress = (address) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      address: address,
    }));
  };

  const setInputCoordinates = (coordinates) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      coordinates: coordinates,
    }));
  };

  const handleChange = (e) => {
    setFormData(prevFormData => ({ ...prevFormData, [e.target.name]: e.target.value }));
  }

  const handleClose = () => {
    closeModal()
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    axiosInstance
      .put(`/admin/users/${currentUser?.id}`, formData)
      .then((response) => {
        toast.success("User details updated successful");
        refreshPage();
        handleClose();
      })
      .catch((err) => {
        toast.error("An error occured. Please, try again");
        if (err.response.status == 422) {
          setValidationError(err.response.data.errors);
        }
        console.log(err.response.data);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setFormData({
        first_name: currentUser?.first_name,
        last_name: currentUser?.last_name,
        address: currentUser?.address,
        phone: currentUser?.phone,
        email: currentUser?.email,
        company_name: currentUser?.company_name,
    });
  }, [currentUser])


  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Edit <span className='text-success'>{currentUser?.last_name} {currentUser?.first_name}</span> Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Form.Label>Name</Form.Label> */}
            <Form.Group className="mb-3" controlId="formBasicLastname">
              <div>
                <h6>First Name</h6>
              </div>
              <Form.Control
                type="text"
                className="form-control"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                required
              />
              {validationError.first_name && <small className="text-danger my-1"> {validationError.first_name[0]} </small>}
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="formBasicFirstname"
            >
              {/* <Form.Label>Description</Form.Label> */}
              <div>
                <h6>Last Name</h6>
              </div>
              <Form.Control
                type="text"
                className="form-control"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                required
              />
              {validationError.last_name && <small className="text-danger my-1"> {validationError.last_name[0]} </small>}
            </Form.Group>
            {currentUser.user_type == 'supplier' && (<Form.Group
              className="mb-3"
              controlId="companyName"
            >
              {/* <Form.Label>Description</Form.Label> */}
              <div>
                <h6>Company Name</h6>
              </div>
              <Form.Control
                name="company_name"
                type="text"
                className="form-control"
                value={formData.company_name}
                onChange={handleChange}
                required
              />
              {validationError.company_name && <small className="text-danger my-1"> {validationError.company_name[0]} </small>}
            </Form.Group>)}
            <div className="row mb-3">
                <h6>Address</h6>
                <div className="col-md-12 col-lg-12">
                    <LocationInput
                    formWrapClass="form-wrap"
                    name="address"
                    inputClass="form-control"
                    handleChange={handleChange}
                    formValue={formData.address}
                    setInputAddress={setInputAddress}
                    setInputCoordinates={setInputCoordinates}
                    checkDeliverability={checkDeliverability}
                    locationClass="d-flex gap-3 special"
                    required
                    />
                    {validationError.address && <small className="text-danger my-1"> {validationError.address[0]} </small>}
                </div>
            </div>

            <Form.Group
              className="mb-3"
              controlId="exampleFormPhone"
            >
              {/* <Form.Label>Description</Form.Label> */}
              <div>
                <h6>Phone</h6>
              </div>
              <Form.Control
                name="phone"
                type="text"
                className="form-control"
                value={formData.phone}
                onChange={handleChange}
              />
              {validationError.phone && <small className="text-danger my-1"> {validationError.phone[0]} </small>}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={handleClose}>
              Close
            </Button>
            <Button className='custom-primary' type='submit'>
              Update
            </Button>
          </Modal.Footer>
        </Form>
        {isLoading && <Loader />}
      </Modal>
    </div>
  );
}
