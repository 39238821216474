import React from 'react'
import './SuccessPage.css'

const SuccessPage = (heading, subHeading, subHeading2) => {
  return (
    <div>
      <div className="success-card card">
        <div  className='success-icon'>
            <i className="checkmark">✓</i>
        </div>
        <h1>Order Successul</h1> 
        <p>Your order was placed successfully.<br/> You will get notified  once  our  supplier accepts!</p>
      </div>
    </div>
  )
}

export default SuccessPage
