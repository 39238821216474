// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import "./CreateVehicle.css";
import axiosInstance from "../../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import Loader from "../../../Utilities/Loader/Loader";
// import axios from 'axios';

export default function CreateVehicle({ show, closeModal, refreshPage }) {
  const [validationError, setValidationError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [years, setYears] = useState(null)
  const [formData, setFormData] = useState({
    brand_name: "",
    model: "",
    year_manufactured: "",
    color: "",
    license_plate: "",
    capacity: "",
    status: ""
  });

  const resetForm = () => {
    setFormData({
      brand_name: "",
      model: "",
      year_manufactured: "",
      color: "",
      license_plate: "",
      capacity: "",
      status: ""
    });
    setValidationError({});
  };

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleClose = () => {
    resetForm();
    closeModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    axiosInstance
      .post(`/user/supplier/vehicle`, formData)
      .then((response) => {
        toast.success(response.data.data.message);
        refreshPage();
        handleClose();
      })
      .catch((err) => {
        toast.error("An error occured. Please, try again");
        if (err.response.status == 422) {
          setValidationError(err.response.data.errors);
        }
        console.log(err.response.data);
      })
      .finally(() => setIsLoading(false));
  };

  const generateYears  = () =>  {
    let currentYear = new Date().getFullYear();
    let startYear = 1980;
    let years = []
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    setYears(years)
  }

  useEffect(() => {generateYears()}, [])

  return (
    <div className="CreateVehicle">
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Add Vehicle Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <Form.Label>Name</Form.Label> */}
            <Form.Group className="mb-3" controlId="formBasicname">
              <div>
                <h6>Brand Name</h6>
              </div>
              <Form.Control
                type="text"
                placeholder=""
                name="brand_name"
                value={formData.brand_name}
                onChange={handleChange}
                required
              />
              {validationError.brand_name && (
                <small className="text-danger my-1">
                  {validationError.brand_name[0]}
                </small>
              )}
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              {/* <Form.Label>Description</Form.Label> */}
              <div>
                <h6>Model</h6>
              </div>
              <Form.Control
                type="text"
                placeholder=""
                name="model"
                value={formData.model}
                onChange={handleChange}
                required
              />
              {validationError.model && (
                <small className="text-danger my-1">
                  {validationError.model[0]}
                </small>
              )}
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              {/* <Form.Label>Description</Form.Label> */}
              <div>
                <h6>Year Manufactured</h6>
              </div>
              <Form.Control 
                as="select"
                name="year_manufactured"
                value={formData.year_manufactured}
                onChange={handleChange}
                required
              >
                <option value="">--Select--</option>
                {years && years.map(year => (
                  <option value={year} key={year}>{year}</option>
                ))}
              </Form.Control>
              {validationError.year_manufactured && (
                <small className="text-danger my-1">
                  {validationError.year_manufactured[0]}
                </small>
              )}
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              {/* <Form.Label>Description</Form.Label> */}
              <div>
                <h6>Color</h6>
              </div>
              <Form.Control
                type="text"
                placeholder=""
                name="color"
                value={formData.color}
                onChange={handleChange}
                required
              />
              {validationError.color && (
                <small className="text-danger my-1">
                  {validationError.color[0]}
                </small>
              )}
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea2"
            >
              {/* <Form.Label>Description</Form.Label> */}
              <div>
                <h6>Capacity (kg)</h6>
              </div>
              <Form.Control
                type="text"
                placeholder=""
                name="capacity"
                value={formData.capacity}
                onChange={handleChange}
                required
              />
              {validationError.capacity && (
                <small className="text-danger my-1">
                  {validationError.capacity[0]}
                </small>
              )}
            </Form.Group>

            <div className="mb-3">
              <label htmlFor="status" style={{ display: "block" }}>
                <div>
                  <h6>Status</h6>
                </div>
                <select
                  className="form-control mt-2"
                  id="status"
                  value={formData.status}
                  onChange={handleChange}
                  name="status"
                >
                  <option value="">--Select--</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={handleClose}>
              Close
            </Button>
            <Button className="custom-primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
        {isLoading && <Loader />}
      </Modal>
    </div>
  );
}
