import React, { useEffect, useState } from 'react'
import AppSidebar from './AppSidebar'
import AppHeader from './AppHeader'
import AppFooter from './AppFooter';

export default function BaseLayout({children}) {
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const scriptUrls = [
        '/assets/js/main.js',
        'https://cdn.jsdelivr.net/npm/apexcharts',
    ];

    useEffect(() => {
        // This function will run when the component mounts
        setIsPageLoaded(true);

        // Import and load your JavaScript script if the page has loaded
        if (isPageLoaded) {
            scriptUrls.forEach(scriptUrl => {
                const script = document.createElement('script');
                script.src = scriptUrl;
                script.async = true;
                document.body.appendChild(script);
            });
        }
    }, [isPageLoaded]);
  return (
    <>
      <AppHeader />
      <AppSidebar />
      <main id="main" className="main">
          {children}
      </main>
      <AppFooter/>
    </>
  )
}
