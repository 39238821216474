import React, { useEffect, useState } from 'react'
import { Link, NavLink, useSearchParams } from 'react-router-dom'
import axiosInstance from '../../hooks/axiosInstance'

const EmailVerified = () => {
  const [loading, setLoading] = useState(false)

  const [queryParameters] = useSearchParams()
  const expires = queryParameters.get('expires')
  const hash = queryParameters.get('hash')
  const id = queryParameters.get('id')
  const signature = queryParameters.get('signature')

  const verifyEmail = () => {
      setLoading(true)
      axiosInstance.get(`/user/auth/email/verify?expires=${expires}&hash=${hash}&id=${id}&signature=${signature}`)
          .then(res => {
              setLoading(false)
          })
          .catch(err => {
              console.log(err.response.data);
          })
  }

  useEffect(() => {
      verifyEmail()
  }, [queryParameters])
  return (
    <div>
      <main style={{ backgroundColor: "#f6f9ff", height: "100vh" }}>
        <div className="container">
          <section className="section register d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-6 d-flex flex-column align-items-center justify-content-center">
                  <div className="d-flex justify-content-center py-4">
                    <Link
                      to="/"
                      className="logo d-flex align-items-center w-auto"
                    >
                      <img src="/assets/images/logo-222x66.png" alt="" />
                      {/* <span className="d-none d-lg-block">NiceAdmin</span> */}
                    </Link>
                  </div>

                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="pt-4 pb-2">
                        <h5 className="card-title text-center pb-0 fs-4">
                          Account Activated !
                        </h5>
                        <div
                            className="alert border-0 alert-info text-center mb-2 mx-2"
                            role="alert"
                          >
                            Thank you, your email has been verified. Your account is now active.
                            Please, login to your account
                          </div>
                      </div>
                      <div className="row mt-0 g-3">
                        <div className="col-12 text-center">
                          <NavLink to="/auth/login" className="btn btn-primary w-50">
                            Go to Login
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  )
}

export default EmailVerified
