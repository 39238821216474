import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import LocationInput from "../FormComponents/LocationInput";
import "./index.css"
import "./navbar.css"
import "./hero.css"
import "./input.css"
import "./services.css"
import "./download-app.css"
import "./box-default.css"
import "./testimonials.css"
import "./sidebars.css"
import "./carousel.css"
import "./order.css"
import "./footer.css"
import "./careers.css"
import useAuth from "../../hooks/useAuthContext";
import axiosInstance from "../../hooks/axiosInstance";

export default function Header() {
  const {userData, resetUser} = useAuth()

  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const navigate = useNavigate()


  const logout = () => {
    const url = userData.userInfo.user_type == 'supplier' || userData.userInfo.user_type == 'user'  ?  '/user/auth/logout'  : '/admin/auth/logout';
    const loginPath  = userData.userInfo.user_type == 'supplier' || userData.userInfo.user_type == 'user'
      ? '/auth/login' : '/auth/login/admin';

    axiosInstance.post(url)
        .then(res => {
            localStorage.removeItem('userData')
            resetUser()
            navigate(loginPath)
        })
        .catch((err) => {
            if (err.response.status == '401'){
                localStorage.removeItem('userData')
                resetUser()
                navigate(loginPath)
            }
        })
  }

  const scriptUrls = ["/assets/js/core.min.js", "/assets/js/webscript.js"];
  useEffect(() => {
    // This function will run when the component mounts
    setIsPageLoaded(true);

    // Import and load your JavaScript script if the page has loaded
    if (isPageLoaded) {
      scriptUrls.forEach((scriptUrl) => {
        const script = document.createElement("script");
        script.src = scriptUrl;
        script.async = true;
        document.body.appendChild(script);
      });
    }
  }, [isPageLoaded]);

  useEffect(() => {

  }, [])
  return (
    <>
      {/* <!-- Page Header--> */}
      <div className="container-fluid navBar">
        <div className="py-3 py-xl-4 child">
          <nav className="d-flex justify-content-between align-items-center mx-0 ms-md-5">
            <div className="logo_div d-flex align-items-center">
              <div className="d-block d-xl-none">
                <div className="hamburger-major">
                  <div className="ham-major-wrapper">
                    <div className="ham-major-item"></div>
                  </div>
                </div>
              </div>
              <NavLink className="brand" to='/'>
                <img src="/assets/images/logo-222x66.png" alt="" width={'50'}/>
              </NavLink>
              <div className="ms-5 justify-content-center gap-5 links d-none d-xl-flex">
                {userData.userInfo && 
                  <NavLink className="rd-nav-link" to="/app/dashboard">
                    Dashboard
                  </NavLink>
                }
                {/* <a href="#" className="text-capitalize text-decoration-none">
                  pages
                </a> */}
              </div>
            </div>
            <div className="nav_links d-flex align-items-center pe-0 pe-sm-5 ps-sm-3">
              {userData.userInfo ?
                <NavLink onClick={() => logout()} className="join text-uppercase">Logout</NavLink>
              :
                <NavLink to="/auth/login" className="join text-uppercase">Login</NavLink>
              }
              <div className="hamburger">
                <div className="wrap d-flex flex-column position-relative align-items-center py-2">
                  <div className="hamburger-item"></div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}
