import React, { useState } from "react";

import "./Support.css";
import { NavLink } from "react-router-dom";
import axiosInstance from "../../../hooks/axiosInstance";
import { toast } from "react-toastify";

const Support = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [validationError, setValidationError] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
    setValidationError({});
  };

  const { name, email, subject, message } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    axiosInstance
      .post(`/user/support`, formData)
      .then((response) => {
        toast.success("Message sent successfully");
        resetForm()
      })
      .catch((err) => {
        toast.error("An error occured. Please, try again");
        if (err.response.status == 422) {
          setValidationError(err.response.data.errors);
        }
        console.log(err.response.data);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <div className="pagetitle">
        <h1>Support</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/app/user/dashboard">Dashboard</NavLink>
            </li>
            <li className="breadcrumb-item active">Support</li>
          </ol>
        </nav>
      </div>
      <div className="row Support">
        <div className="col-lg-7 mt-5 mt-lg-0 align-items-stretch">
          <div className=" contact p-3">
            <form onSubmit={handleSubmit} className="php-email-form">
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="name">Your Name</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    value={name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="email">Your Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    value={email}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="subject">Subject</label>
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  value={subject}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  className="form-control"
                  name="message"
                  rows="10"
                  value={message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <div className="text-center mt-3">
                <button type="submit" disabled={isLoading}>
                  {isLoading ? (
                    <div className="spinner-border spinner-border-sm text-light"></div>
                  ) : (
                    "Send Message"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="col-lg-5 align-items-stretch ">
          <div className="info">
            {/* <div className="address">
              <i className="bi bi-geo-alt"></i>
              <h4>Location:</h4>
              <p>8B Ogidi Crescent, Lekki Phase 1, Lagos</p>
            </div> */}
            <div className="email">
              <i className="bi bi-envelope"></i>
              <h4>Email:</h4>
              <p>info@innermember.com</p>
            </div>
            <div className="phone">
              <i className="bi bi-phone"></i>
              <h4>Call:</h4>
              <p>+234 703 410 7070</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
