import React, { useEffect, useState } from "react";
import Footer from "../../../components/WebLayouts/Footer";
import Header2 from "../../../components/WebLayouts/Header2";
import data from "../../../data.js";
import "./PlaceOrder.css";
import { NavLink, useNavigate } from "react-router-dom";
import LocationInput from "../../../components/FormComponents/LocationInput.jsx";
import Header from "../../../components/WebLayouts/Header.jsx";
import { toast } from "react-toastify";
import axiosInstance from "../../../hooks/axiosInstance.js";
import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import SuccessPage from "../../../components/Utilities/SuccessPage/SuccessPage.jsx";

export default function PlaceOrder() {
  const [selectedImg, setSelectedImg] = useState(
    "https://easygasng.com/wp-content/uploads/2015/11/Refill.jpg"
  );

  const [isDeliverable, setIsDeliverable] = useState(true)
  const [totalAmount, setTotalAmount]  = useState(null)
  const [loading,setLoading]  =  useState(false)
  const [orderSuccess,  setOrderSuccess] = useState(false)

  const [formData, setFormData] = useState({
    currentAddress: "",
    coordinates: "",
    quantity: '',
    priority: '',
    phone: '',
    currentAddress: "",
    coordinates: "",
    total_amount: ""
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(!isDeliverable) {
      toast.error("Order cannot be fuflfilled for selected area")
      return
    } 
    setLoading(true)
    formData.total_amount = totalAmount
    localStorage.setItem('orderData', JSON.stringify(formData))
    axiosInstance.post('/user/order', formData)
              .then(res  => {
                localStorage.setItem('orderUrl', "/app/user/order/details")
                setOrderSuccess(true)
                showOrder()
              })
              .catch(err => {
                console.error(err.response)
              })
              .finally(() => setLoading(false))
  };

  const showOrder = ()  => {
    setTimeout(() => {
      setOrderSuccess(false)
      navigate("/app/user/order/details");
    }, 2000);
  }

  const checkDeliverability = (status) => {
    setIsDeliverable(status)
  }

  const setInputAddress = (address) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      currentAddress: address,
    }));
  };

  const setInputCoordinates = (coordinates) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      coordinates: coordinates,
    }));
  };

  const fetchGasPricing = () => {
    axiosInstance.get('/generic/gas_pricing')
        .then(res => {
          const total  = formData.quantity * res.data.data.items[0].price
          setTotalAmount(total)
        })
  }

  useEffect(() =>  {
    const orderData = localStorage.getItem('orderData');

    if(orderData) {
      setFormData(JSON.parse(orderData))
    }
  }, [])

  useEffect(() => {
    fetchGasPricing()
  }, [formData.quantity])

  return (
    <div className="page">
      <Header />
      {/* <!-- Career --> */}
      <section className="d-flex flex-column text-center career-img ">
        <div className="careers">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col xl-9">
                <h2 className="text-uppercase text-light fw-bold">Gas Order</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="career">
          <div className="container">
            <ul className="career_list">
              <li className="text-uppercase"><NavLink to="/">Home</NavLink></li>
              <li className="text-uppercase"><a href="#">Order</a></li>
            </ul>
          </div>
        </div>
      </section>
      {/* <!-- Your Career Starts Here!--> */}
      <section className="vacancies">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 order-lg-1 order-2">
              <div className="card">
                <div className="card-body py-md-5 px-md-5">
                  <div className="row">
                    <div className="col-lg-5 d-flex align-items-center justify-content-center">
                      <img src={selectedImg} alt="" />
                    </div>
                    <div className="col-lg-7">
                      <div className="_form-2">
                        <form onSubmit={handleSubmit}>
                          <div className="input-wrap mb-4">
                            <input
                              type="number"
                              className="_input"
                              placeholder="Quantity (kg)"
                              name="quantity"
                              onChange={handleChange}
                              value={formData.quantity}
                              required
                            />
                          </div>
                          <div className="mb-4">
                            <select 
                              name="priority"
                              onChange={handleChange}
                              value={formData.priority}
                              required
                            >
                              <option value="">Priority</option>
                              <option value="high">High</option>
                              <option value="medium">Medium</option>
                              <option value="low">Low</option>
                            </select>
                          </div>
                          <LocationInput
                            formWrapClass="mb-4"
                            name="currentAddress"
                            inputClass="input-wrap-2"
                            handleChange={handleChange}
                            formValue={formData.currentAddress}
                            setInputAddress={setInputAddress}
                            setInputCoordinates={setInputCoordinates}
                            checkDeliverability={checkDeliverability}
                            locationClass="d-flex gap-3 address-control special"
                            required={true}
                          />
                          <div className="input-wrap mb-4">
                            <input
                              type="number"
                              className="_input"
                              placeholder="Your Phone Number"
                              name="phone"
                              value={formData.phone}
                              onChange={handleChange}
                            />
                          </div>
                            <div className="btn_wrap ps-sm-0">
                              <button className="btn text-uppercase w-100 py-3" disabled={loading}>
                                { loading ? 'Submitting...' : 'Proceed with order'}
                              </button>
                            </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 order-lg-2 order-1 mb-3">
              <div className="card">
                <div className="card-header bg-white">
                  <p className="font-weight-normal">Order Summary</p>
                </div>
                <div className="card-body">
                  <div className="d-flex items">
                    <div>
                      <p>Item total</p>
                    </div>
                    <div>
                      <p>₦{Number(totalAmount).toLocaleString()}</p>
                    </div>
                  </div>
                  <div className="d-flex items">
                    <div>
                      <p>Delivery Fees</p>
                    </div>
                    <div>
                      <p className="text-primary" style={{ fontSize: "12px" }}>
                        Not calculated yet
                      </p>
                    </div>
                  </div>
                  <div className="d-flex items">
                    <div>
                      <p>Total</p>
                    </div>
                    <div>
                      <p>₦{Number(totalAmount).toLocaleString()}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <>
        <Dialog
          open={orderSuccess}
          // onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* <DialogTitle id="alert-dialog-title">
            Delete <b className="text-primary">{currentVehicle.brand_name} {currentVehicle.model}</b>{" "}
          </DialogTitle> */}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <SuccessPage 
                heading={'Order Successul'}
                subHeading={'Your order was placed successfully.'}
                subHeading2={'You will get notified  once  our  supplier accepts!'}
              />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </>

      <Footer />
    </div>
  );
}
