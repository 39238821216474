import React, { useEffect, useState } from "react";
import "./History.css";
import { Link, NavLink } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Pagination,
} from "@mui/material";
import axiosInstance from "../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import Loader from "../../../components/Utilities/Loader/Loader";
import ViewHistory from "../../../components/Modal/ViewHistory";

export default function History() {
  const [orderHistory, setOrderHistory] = useState([]);
  const [currentHistory,setCurrentHistory] = useState(null)
  const [filter, setFilter] = useState("");
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [loading, setLoading] = useState(false);

  //  state for pagination
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);

  //  pagination function
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleShow = async(history) => {
    await setCurrentHistory(history)
    setShow(true)
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleRefresh = () => {
    setRefreshKey((prevRefreshKey) => prevRefreshKey + 1);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  // const handleDeleteOpen = async (data) => {
  //   await setCurrentVehicle(data);
  //   await setDeleteOpen(true);
  // };

  // const handleDeleteClose = () => {
  //   setDeleteOpen(false);
  // };

  const fetchOrderHistory = () => {
    setLoading(true);
    axiosInstance
      .get(
        `/user/order/supplier_orders?page=${page}&limit=${perPage}&filter=${filter}`
      )
      .then((response) => {
        setOrderHistory(response.data.data.data);
        const pageCount = Math.ceil(
          response.data.data.total / response.data.data.per_page
        );
        setPageCount(pageCount);
      })
      .catch((err) => {
        toast.error("An error occured. Please, try again");
      })
      .finally(() => setLoading(false));
  };

  // const handleDelete = () => {
  //   setLoading(true);
  //   axiosInstance
  //     .delete(
  //       `/user/supplier/vehicle/${currentVehicle?.id}`
  //     )
  //     .then((response) => {
  //       toast.success("Vehicle deleted successfully")
  //       setDeleteOpen(false)
  //       handleRefresh()
  //     })
  //     .catch((err) => {
  //       toast.error("An error occured. Please, try again");
  //     })
  //     .finally(() => setLoading(false));
  // };

  const filterHistory = () => {
    fetchOrderHistory();
  };

  useEffect(() => {
    fetchOrderHistory();
  }, [page, perPage, refreshKey]);

  return (
    <>
      <div className="pagetitle">
        <h1>Order History</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/app/user/dashboard">Dashboard</NavLink>
            </li>
            <li className="breadcrumb-item">
              <NavLink to="/app/user/order">Order</NavLink>
            </li>
            <li className="breadcrumb-item active">History</li>
          </ol>
        </nav>
      </div>

      <section className="section vehicle">
        <div className="row">
          <div className="col-lg-12 col-md-12 order-2 order-sm-1 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div>
                    <h4 className="card-title d-flex align-items-center">
                      History
                    </h4>
                  </div>
                </div>

                {/* Filter input */}
                <div className="row mb-3">
                  <div className="col-lg-3">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Order Number..."
                        value={filter}
                        onChange={handleFilterChange}
                      />
                      <button
                        onClick={filterHistory}
                        className="btn btn-primary"
                        type="submit"
                      >
                        Go
                      </button>
                    </div>
                  </div>
                </div>

                {orderHistory && orderHistory.length > 0 ? (
                  <>
                    {/* <!-- Start Table --> */}
                    <div className="scroll-area-lg">
                      <div className="table-responsive-sm">
                        <table className="table table-striped table-borderless table-hover ">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Order No.</th>
                              <th width="25%" scope="col">Customer</th>
                              <th scope="col">Quantity (kg)</th>
                              <th scope="col">Amount (₦)</th>
                              <th scope="col">Delivery (₦)</th>
                              <th scope="col">Priority</th>
                              <th scope="col">Status</th>
                              <th scope="col">Payment</th>
                              <th scope="col" width="5%"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {orderHistory.map((history, i) => (
                              <tr key={history.id}>
                                <th scope="row">{i + 1}</th>
                                <td>{history.order_number}</td>
                                <td>
                                  <p className="mb-0" style={{fontWeight: '600'}}>
                                    {history?.customer?.last_name} {history?.customer?.first_name}
                                  </p>
                                  <p className="mb-0" style={{fontSize: '13px'}}>
                                    {history?.customer?.phone}
                                  </p>
                                </td>
                                <td>{history.quantity}</td>
                                <td>{Number(history.order_amount).toLocaleString()}</td>
                                <td>
                                  {history.delivery_price ?
                                    Number(history.delivery_price).toLocaleString()
                                    :
                                    '-'
                                  }
                                </td>
                                <td
                                >
                                  {history.priority === "high" ? (
                                    <span className="badge bg-success text-uppercase">
                                      {history.priority}
                                    </span>
                                  ) : history.priority === "medium" ? (
                                    <span className="badge bg-info text-uppercase">
                                      {history.priority}
                                    </span>
                                  ) : (
                                    <span className="badge bg-danger text-uppercase">
                                      {history.priority}
                                    </span>
                                  )}
                                </td>

                                <td
                                >
                                  {history.status === "delivered" ? (
                                    <span className="badge bg-success text-capitalize">
                                      {history.status}
                                    </span>
                                  ) : history.status === "pending" ? (
                                    <span className="badge bg-warning text-capitalize">
                                      {history.status}
                                    </span>
                                  ) : (
                                    <span className=" badge bg-danger text-capitalize">
                                      {history.status}
                                    </span>
                                  )}
                                </td>

                                <td
                                >
                                  {history.payment_status === "approved" ? (
                                    <span className="badge bg-success text-capitalize">
                                      {history.payment_status}
                                    </span>
                                  ) : history.payment_status === "pending" ? (
                                    <span className="badge bg-warning text-capitalize">
                                      {history.payment_status}
                                    </span>
                                  ) : (
                                    <span className="badge bg-danger text-capitalize">
                                      {history.payment_status == 'approved' ? 'Paid' : history.payment_status}
                                    </span>
                                  )}
                                </td>

                                <td className="dropdown">
                                  <Link
                                    className="dropdown-toggle menu"
                                    id={`actionDropdown_${history.id}`}
                                    href="#"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="mdi mdi-apps"></i>
                                  </Link>
                                  <div
                                    className="dropdown-menu dropdown-menu-right preview-list"
                                    aria-labelledby={`actionDropdown_${history.id}`}
                                  >
                                    {/* {(history.status ==  'pending' || history.payment_status ==  'pending') && <Link
                                      className="dropdown-item custom-drop"
                                      to={`/app/user/order/details/${history.id}`}
                                    >
                                      <i className="mdi mdi-pencil mr-2 text-success"></i>{" "}
                                      <span>Continue Order</span>
                                    </Link>} */}
                                    
                                    {history.status ==  'delivered' && <Link
                                      className="dropdown-item custom-drop"
                                      onClick={() => handleShow(history)}
                                    >
                                      <i className="mdi mdi-pencil mr-2 text-success"></i>{" "}
                                      <span>View</span>
                                    </Link>}
                                    
                                    {/* <Link
                                      className="dropdown-item custom-drop"
                                      
                                    >
                                      <i className="mdi mdi-pencil mr-2 text-success"></i>{" "}
                                      <span>Print</span>
                                    </Link> */}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end mt-2">
                      <div className="d-flex">
                        per page
                        <select
                          className="ml-1"
                          name="perPage"
                          onChange={(e) => setPerPage(e.target.value)}
                          value={perPage}
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                        </select>
                      </div>
                      <Pagination
                        onChange={handlePageChange}
                        count={pageCount}
                        color="primary"
                        shape="rounded"
                        size="small"
                        page={page}
                      />
                    </div>
                    {/* <!-- End Table --> */}
                  </>
                ) : (
                  <div className="alert alert-info mt-4">No record found</div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <CreateVehicle
          show={show}
          closeModal={handleClose}
          refreshPage={handleRefresh}
        />
        {currentVehicle && <EditVehicle
          show={showEdit}
          closeModal={handleClose}
          refreshPage={handleRefresh}
          currentVehicle={currentVehicle}
        />}
        {currentVehicle && <RefillVehicle
          show={showRefill}
          closeModal={handleClose}
          refreshPage={handleRefresh}
          currentVehicle={currentVehicle}
        />}
        {currentVehicle && <ViewGasHistory
          show={showHistory}
          closeModal={handleClose}
          refreshPage={handleRefresh}
          currentVehicle={currentVehicle}
        />} */}

        {/* Delete Dialog */}
        {/* {currentVehicle && (
          <>
            <Dialog
              open={deleteOpen}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Delete <b className="text-primary">{currentVehicle.brand_name} {currentVehicle.model}</b>{" "}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to perform this operation?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button className="text-danger" onClick={handleDeleteClose}>
                  Cancel
                </Button>
                <Button onClick={handleDelete} autoFocus>
                  Continue
                </Button>
              </DialogActions>
              {isDeleteLoading && <Loader />}
            </Dialog>
          </>
        )} */}

        {loading && <Loader />}
      </section>

      <ViewHistory
        show={show}
        closeModal={handleClose}
        currentHistory={currentHistory}
        refreshPage={handleRefresh}
      />
    </>
  );
}
