import React, { useEffect, useState } from "react";
import "./DeliveryPricing.css";
import { Link, NavLink } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Pagination, Stack, Typography } from "@mui/material";
import Loader from "../../../components/Utilities/Loader/Loader";
import { toast } from "react-toastify";
import EditDeliveryPrice from "../../../components/Modal/Admin/DeliveryPricing/EditDeliveryPrice";
import CreateDeliveryPrice  from "../../../components/Modal/Admin/DeliveryPricing/CreateDeliveryPrice";
import ViewDeliveryPrice from "../../../components/Modal/Admin/DeliveryPricing/ViewDeliveryPrice";
import axiosInstanceAdmin from "../../../hooks/axiosInstanceAdmin";


export default function DeliveryPricing() {
  const [deliveryPricing, setGaspricing] = useState([]);
  const [filter, setFilter] = useState("");
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentDeliveryPricing, setCurrentDeliveryPricing] = useState(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [ShowView, setShowView] = useState(false);


  //  state for pagination
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);

  //  pagination function
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleShow = () => setShow(true);

  const handleShowEdit = (data) => {
    setCurrentDeliveryPricing(data);
    setShowEdit(true);
  };

  const handleShowView = (data) => {
    setCurrentDeliveryPricing(data);
    
    setShowView(true);
  };



  const handleShowHistory = (data) => {
    setCurrentDeliveryPricing(data);
    setShowHistory(true);
  }

  const handleClose = () => {
    setShow(false);
    setShowEdit(false);
    setShowHistory(false)
    setShowView(false);

  };
  const handleRefresh = () => {
    setRefreshKey((prevRefreshKey) => prevRefreshKey + 1);
  };

  const handleDeleteOpen = async (data) => {
    await setCurrentDeliveryPricing(data);
    await setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const fetchDeliveryPricing = () => {
    setLoading(true);
    axiosInstanceAdmin
      .get(
        `/admin/delivery-pricings?page=${page}&limit=${perPage}&filter=${filter}`
      )
      .then((response) => {
        setGaspricing(response.data.data.data.data);
        const pageCount = Math.ceil(
          response.data.data.total / response.data.data.per_page
        );
        setPageCount(pageCount);
      })
      .catch((err) => {
        toast.error("An error occured. Please, try again");
      })
      .finally(() => setLoading(false));
  };

  const handleDelete = () => {
    setLoading(true);
    axiosInstanceAdmin
      .delete(
        `/admin/delivery-pricings/${currentDeliveryPricing?.id}`
      )
      .then((response) => {
        toast.success("Delivery Pricing deleted successfully")
        setDeleteOpen(false)
        handleRefresh()
      })
      .catch((err) => {
        toast.error("An error occured. Please, try again");
      })
      .finally(() => setLoading(false));
  };


  useEffect(() => {
    fetchDeliveryPricing();
  }, [page, perPage, refreshKey]);


  return (
    <>
      <div className="pagetitle">
        <h1>Delivery Pricing</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/app/dashboard">Dashboard</NavLink>
            </li>
            <li className="breadcrumb-item active">Delivery Pricing</li>
          </ol>
        </nav>
      </div>

      <section className="section vehicle">
        <div className="row">
          <div className="col-lg-12 col-md-12 order-2 order-sm-1 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div>
                    <h4 className="card-title d-flex align-items-center">
                      Delivery Pricing
                    </h4>
                  </div>
                  <div>
                    <button
                      className="vehicle-button mt-3 btn btn-primary"
                      onClick={handleShow}
                    >
                      <i className="mdi mdi-plus-circle-outline"></i> New
                      Delivery Pricing
                    </button>
                  </div>
                </div>

                {/* Filter input */}
                {/* <div className="row mb-3">
                  <div className="col-lg-3">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={filter}
                        onChange={handleFilterChange}
                      />
                      <button
                        onClick={filterVehicle}
                        className="btn btn-primary"
                        type="submit"
                      >
                        Go
                      </button>
                    </div>
                  </div>
                </div> */}

                {deliveryPricing && deliveryPricing.length > 0 ? (
                  <>
                    {/* <!-- Start Table --> */}
                    <div className="scroll-area-lg">
                      <div className="table-responsive-sm">
                        <table className="table table-striped table-borderless table-hover ">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Min Distance (km)</th>
                              <th scope="col">Max Distance (km)</th>
                              <th scope="col">Price(₦)</th>
                              <th scope="col">Status</th>
                              <th scope="col" width="5%"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {deliveryPricing.map((d_p, i) => (
                              <tr key={d_p.id}>
                                <th scope="row">{i + 1}</th>
                              
                                <td>{d_p.min_distance ? d_p.min_distance : '-'}</td>
                                <td>{d_p.max_distance ? d_p.max_distance : '-'}</td>
                                <td>{d_p.price ? Number(d_p.price).toLocaleString() : '-'}</td>
                                <td>
                                  <span
                                    className={`badge text-capitalize ${
                                      d_p.status == "active"
                                        ? "bg-success"
                                        : "bg-danger"
                                    }`}
                                  >
                                    {d_p.status}
                                  </span>
                                </td>
                                <td className="dropdown">
                                  <Link
                                    className="dropdown-toggle menu"
                                    id={`actionDropdown_${d_p.id}`}
                                    href="#"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="mdi mdi-apps"></i>
                                  </Link>
                                  <div
                                    className="dropdown-menu dropdown-menu-right preview-list"
                                    aria-labelledby={`actionDropdown_${d_p.id}`}
                                  >
                                    <Link
                                      className="dropdown-item custom-drop"
                                      onClick={() => handleShowEdit(d_p)}
                                    >
                                      <i className="mdi mdi-pencil mr-2 text-success"></i>{" "}
                                      <span>Edit</span>
                                    </Link>
                                    <div className="dropdown-divider"></div>
                                    {/* <Link
                                      className="dropdown-item custom-drop"
                                      onClick={() => handleShowView(d_p)}
                                    >
                                      <i className="mdi mdi-pencil mr-2 text-success"></i>{" "}
                                      <span>View</span>
                                    </Link> */}
                                   
                                    
                                    <div className="dropdown-divider"></div>
                                    <Link className="dropdown-item custom-drop" onClick={() => handleDeleteOpen(d_p)}>
                                      <i className="bi bi-trash-fill text-danger"></i>{" "}
                                      <span>Delete</span>
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end mt-2">
                      <div className="d-flex">
                        per page
                        <select
                          className="ml-1"
                          name="perPage"
                          onChange={(e) => setPerPage(e.target.value)}
                          value={perPage}
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                        </select>
                      </div>
                      {/* <div className="pagination">
                    <Stack spacing={2}>
                      <Pagination
                        sx={{ color: "#09b294" }}
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                      />
                    </Stack>
                  </div> */}
                      <Pagination
                        onChange={handlePageChange}
                        count={pageCount}
                        color="primary"
                        shape="rounded"
                        size="small"
                        page={page}
                      />
                    </div>
                    {/* <!-- End Table --> */}
                  </>
                ) : (
                  <div className="alert alert-info mt-4">No record found</div>
                )}
              </div>
            </div>
          </div>
        </div>

        <CreateDeliveryPrice
          show={show}
          closeModal={handleClose}
          refreshPage={handleRefresh}
        />

        {currentDeliveryPricing && <EditDeliveryPrice
          show={showEdit}
          closeModal={handleClose}
          refreshPage={handleRefresh}
          currentDeliveryPrice={currentDeliveryPricing}
        />}
       
       
       {currentDeliveryPricing && <ViewDeliveryPrice
          show={ShowView}
          closeModal={handleClose}
          refreshPage={handleRefresh}
          currentDeliveryPrice={currentDeliveryPricing}
        />}


        {/* Delete Dialog */}
        {currentDeliveryPricing && (
          <>
            <Dialog
              open={deleteOpen}
              onClose={handleDeleteClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Delete <b className="text-primary">Delivery Pricing</b>{" "}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to perform this operation?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button className="text-danger" onClick={handleDeleteClose}>
                  Cancel
                </Button>
                <Button onClick={handleDelete} autoFocus>
                  Continue
                </Button>
              </DialogActions>
              {isDeleteLoading && <Loader />}
            </Dialog>
          </>
        )}

        {loading && <Loader />}
      </section>
    </>
  );
}
