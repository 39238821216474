import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import "./Details.css"
import axiosInstance from "../../../hooks/axiosInstance";
import Map from "../../../components/Modal/DeliveryMap/Map";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Loader from "../../../components/Utilities/Loader/Loader";
import SuccessPage from "../../../components/Utilities/SuccessPage/SuccessPage";
import useAuth from "../../../hooks/useAuthContext";
import { toast } from "react-toastify";
// import * as PusherPushNotifications from "@pusher/push-notifications-web"
import Pusher from 'pusher-js';

export default function Details() {
    const appKey =  process.env.NODE_ENV !== 'production' ? 
            process.env.REACT_APP_DEV_PUSHER_KEY :
                process.env.REACT_APP_PROD_PUSHER_KEY

    const appCluster =  process.env.NODE_ENV !== 'production' ? 
                process.env.REACT_APP_DEV_PUSHER_CLUSTER :
                    process.env.REACT_APP_PROD_PUSHER_CLUSTER

    const navigate = useNavigate()
    const {userData}  = useAuth()
    const {orderId} = useParams()

    const [supplierAccept, setSupplierAccept] = useState(false)
    const [loading, setLoading] = useState(false)
    const [currentOrder, setCurrentOrder] =  useState(null)
    const [confirmDelivery, setConfirmDelivery] = useState(false)
    const [confirmSupply, setConfirmSupply] = useState(false)
    const [orderSuccess,  setOrderSuccess] = useState(false)
    const [orderCancel,  setOrderCancel] = useState(false)

    const fetchCurrentOrder = () => {
        const url = userData?.userInfo?.user_type  ==  'user' && !orderId ? '/user/order/fetch' : 
                    userData?.userInfo?.user_type  ==  'user' && orderId ? `/user/order/fetch/${orderId}` :
                    `/user/order/supplier/${orderId}` 
        axiosInstance.get(url)
            .then(res => {
                setCurrentOrder(res.data.data)
                setOrderSuccess(false)
            })
            .catch(err  => console.error(err))
            .finally(() => setLoading(false))
    }

    const handleOrderConfirm = () => {
        setLoading(true)
        axiosInstance.post(`/user/order/customer/confirm/${currentOrder.id}`)
            .then(res => {
                setConfirmSupply(false)
                setOrderSuccess(true)
                showOrder()
            })
            .catch(err  => console.error(err))
            .finally(() => setLoading(false))
    }

    const handleOrderSupply = () => {
        setLoading(true)
        const url = userData?.userInfo?.user_type  ==  'user'  ? `/user/order/customer/confirm/${currentOrder.id}`  :
                        `/user/order/supplier/confirm/${currentOrder.id}`
        axiosInstance.post(url)
            .then(res => {
                setConfirmSupply(false)
                setOrderSuccess(true)
                fetchCurrentOrder()
            })
            .catch(err  => console.error(err))
            .finally(() => setLoading(false))
    }

    const confirmCashPayment = () => {
        setLoading(true)
        axiosInstance.post('/user/order/cash_payment/verify',{
            order_id: currentOrder.id
        })
            .then(res => {
                toast.success('Payment verification successful')
                fetchCurrentOrder()
            })
            .catch(err  => console.error(err))
            .finally(() => setLoading(false))
    }

    const handleOrderCancel = () => {
        setLoading(true)
        axiosInstance.post(`/user/order/customer/cancel/${currentOrder.id}`)
            .then(res => {
                toast.success("Order cancelled successfully")
                navigate("/app/user/order")
            })
            .catch(err  => console.error(err))
            .finally(() => setLoading(false))
    }


    const showOrder = ()  => {
        setTimeout(() => {
          setOrderSuccess(false)
          navigate(`/app/user/order/details/invoice/${currentOrder.id}`);
        }, 2000);
    }

    useEffect(() => {
        const pusher = new Pusher(appKey, {
          cluster: appCluster, // If applicable
        });
    
        const channel = pusher.subscribe('order-accepted'); // Subscribe to your custom channel
        const supplierConfirmChannel = pusher.subscribe('supplier-accept-order'); // Subscribe to your custom channel
        const customerConfirmChannel = pusher.subscribe('customer-accept-order'); // Subscribe to your custom channel
    
        channel.bind('order.accepted', (data) => { // Listen for the custom event
            fetchCurrentOrder()
        });

        supplierConfirmChannel.bind('order.supplier.accept', (data) => { // Listen for the custom event
            fetchCurrentOrder()
        });

        customerConfirmChannel.bind('order.customer.accept', (data) => { // Listen for the custom event
            fetchCurrentOrder()
        });
    
        return () => {
          pusher.disconnect(); // Disconnect on component unmount
        };
    }, []);

    useEffect(() => {
        fetchCurrentOrder()
        setTimeout(() => {
            setSupplierAccept(true)
        }, 5000);
    }, [])
  return (
    <>
      <div className="pagetitle">
        <h1>Request Details</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/app/user/dashboard">Dashboard</NavLink>
            </li>
            <li className="breadcrumb-item">
              <NavLink to="/app/user/order">Order</NavLink>
            </li>
            <li className="breadcrumb-item active">Details</li>
          </ol>
        </nav>
      </div>
      {/* <!-- End Page Title --> */}

      <section className="section vehicle">
        <div className="row">
            {userData?.userInfo?.user_type  ==  'supplier' && <div className="col-lg-12 mb-4">
                <Link to="/app/supplier/order" className="btn btn-outline-dark me-4">
                    New Requests
                </Link>
                <Link to="/app/supplier/history/pending" className="btn btn-outline-danger">
                    Pending Requests
                </Link>
            </div>}
          <div className="col-lg-12">
            <div className="card">
                <div>
                    <Map 
                        customerLocation={currentOrder?.delivery_location}
                        supplierLocation={currentOrder?.supplier?.location}    
                    />
                </div>
              <div className="card-body">
                <div className="row">
                    <div className={`${currentOrder && currentOrder.supplier ? 'col-md-4' : 'col-md-6'} mb-3 order-lg-1 order-2`}>
                        <div className="order-details-wrapper">
                            <h5 className="card-title order-title">Order Details</h5>
                            <p className="d-flex">
                                <i className="bi bi-droplet-half text-dark"></i>
                                <span className="ml-3"><b>Quantity:</b> {currentOrder?.quantity}kg</span>
                            </p>
                            <p className="d-flex">
                                <i className="bi bi-clock text-dark"></i>
                                <span><b>Total Amount:</b> &nbsp;
                                    <b className="text-danger">₦{Number(currentOrder?.order_amount).toLocaleString()}</b>
                                </span>
                            </p>
                            <p className="d-flex">
                                <i className="bi bi-geo-alt text-dark"></i>
                                <span><b>Address:</b> {currentOrder?.delivery_address}</span>
                            </p>
                            <p className="d-flex">
                                <i className="bi bi-pin-angle text-dark"></i>
                                <span><b>Priority:</b> 
                                    <span className="badge rounded-pill bg-danger text-uppercase">
                                        {currentOrder?.priority}
                                    </span>
                                </span>
                            </p>
                            <p className="d-flex">
                                <i className="bi bi-clock text-dark"></i>
                                <span><b>Estimated Time:</b> {currentOrder?.delivery_time}</span>
                            </p>
                        </div>
                    </div>
                    <div className={`${currentOrder && currentOrder.supplier ? 'col-md-4' : 'col-md-6'} order-lg-2 order-3`}>
                        {currentOrder && currentOrder.supplier ?
                            <div className="order-details-wrapper">
                                <h5 className="card-title  order-title">Supplier Info</h5>
                                <p className="d-flex">
                                    <i className="bi bi-person text-dark"></i>
                                    <span><b>Name:</b> {currentOrder?.supplier?.last_name} {currentOrder?.supplier?.first_name}</span>
                                </p>
                                <p className="d-flex">
                                    <i className="bi bi-phone text-dark"></i>
                                    <span><b>Phone:</b> {currentOrder?.supplier?.phone}</span>
                                </p>
                                <p className="d-flex">
                                    <i className="bi bi-truck text-dark"></i>
                                    <span><b>Vehicle Model:</b> {currentOrder?.vehicle?.year_manufactured}&nbsp; 
                                        {currentOrder?.vehicle?.brand_name} {currentOrder?.vehicle?.model}
                                    </span>
                                </p>
                                <p className="d-flex">
                                    <i className="bi bi-truck text-dark"></i>
                                    <span><b>Plate Number:</b> {currentOrder?.vehicle?.license_plate}</span>
                                </p>
                                <p className="d-flex">
                                    <i className="bi bi-truck text-dark"></i>
                                    <span><b>Color:</b> {currentOrder?.vehicle?.color}</span>
                                </p>
                            </div>
                        :
                            <div className="order-details-wrapper py-lg-4">
                                <div className="alert alert-info">
                                    <div className="d-flex">
                                        <i className="bi bi-info-circle"></i>
                                        <span style={{marginLeft: '10px'}}>
                                            Kindly wait for a supplier to accept your request. 
                                            Their information will show here once accepted.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {currentOrder  && currentOrder.supplier  && 
                        <div className="col-md-4 py-3 order-lg-3 order-1">
                            <div className="row">
                                {userData?.userInfo?.user_type  ==  'user' && currentOrder.status == 'pending' && (
                                    <>
                                        {currentOrder.supply_status  == 'delivered' && <div className="col-lg-6 mb-3">
                                            <button className="btn btn-primary w-100" onClick={() => setConfirmDelivery(true)}>
                                                Confirm Delivery
                                            </button>
                                        </div>}
                                        {currentOrder.supply_status  != 'delivered' && <div className="col-lg-6 mb-3">
                                            <button className="btn btn-danger w-100" onClick={() => setOrderCancel(true)}>
                                                Cancel
                                            </button>
                                        </div>}
                                    </>
                                )}
                                {userData?.userInfo?.user_type  ==  'supplier' && (
                                    <>
                                        {currentOrder.supply_status == 'pending'  && currentOrder.status != 'cancelled' && <div className="col-lg-12 mb-3">
                                            <button className="btn btn-primary w-100" onClick={() => setConfirmSupply(true)}>
                                                Confirm Supply
                                            </button>
                                        </div>}
                                        {currentOrder.supply_status == 'delivered' && currentOrder.payment_status != 'approved' && (<>
                                            <p style={{fontSize: '12px', lineHeight: 1.5, fontWeight: '600'}}
                                            className="text-muted">
                                                If customer paid by cash or bank transfer, 
                                                    kindly confirm payment with the button below: 
                                                
                                            </p>
                                            <div className="col-lg-12 mb-3">
                                                <button className="btn btn-info w-100" onClick={confirmCashPayment}>
                                                    Confirm Payment
                                                </button>
                                            </div>
                                        </>)}
                                        {(currentOrder.payment_status == 'approved' || currentOrder.status == 'cancelled') && <div className="col-lg-12 mb-3">
                                            <Link to="/app/supplier/history" className="btn btn-danger w-100">
                                                Close Order
                                            </Link>
                                        </div>}
                                    </>
                                )}
                                {/* <div className="col-12 mb-3">
                                    <button className="btn btn-warning w-100">
                                        Make Payment
                                    </button>
                                </div>
                                <div className="col-12 mb-3">
                                    <NavLink className="btn btn-info w-100" to="/app/user/order/details/invoice">
                                        View Bill
                                    </NavLink>
                                </div> */}
                            </div>  
                        </div>
                    }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

        {/* user  confirm Order acceptance */}
        <>
            <Dialog
                open={confirmDelivery}
                onClose={() => setConfirmDelivery(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {loading && <Loader />}
                <DialogTitle id="alert-dialog-title">
                    Confirm delivery of <b className="text-primary">{currentOrder?.quantity}kg</b> gas
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    You are confirming the requested gas quantity has been delivered and filled?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className='text-danger' onClick={() => setConfirmDelivery(false)}>No</Button>
                    <Button onClick={handleOrderConfirm} autoFocus>
                        Yes, confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {/* order success popup */}
            <Dialog
                open={orderSuccess}
                // onClose={handleDeleteClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    <SuccessPage 
                        heading={userData?.userInfo?.user_type  ==  'user' ? 'Order Delivery Confirmed' : 'Order supply confirmed'}
                        subHeading={userData?.userInfo?.user_type  ==  'user' ? 
                            'You have confirmed the successful delivery of your order.' : 'You have confirmed the successful supply of user order'}
                        subHeading2={userData?.userInfo?.user_type  ==  'user' ? 'You will be routed to the payment page now' : 
                                    ''
                        }
                    />
                    </DialogContentText>
                </DialogContent>
            </Dialog>

            {/* Order cancel prompt */}
            <Dialog
                open={orderCancel}
                onClose={() => setOrderCancel(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {loading && <Loader />}
                <DialogTitle id="alert-dialog-title">
                    Cancel <b className="text-primary">{currentOrder?.quantity}kg</b> gas order
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to cancel the order?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className='text-danger' onClick={() => setOrderCancel(false)}>No</Button>
                    <Button onClick={handleOrderCancel} autoFocus>
                        Yes, continue
                    </Button>
                </DialogActions>
            </Dialog>
        </>

        {/* supply confirm delivery */}
        <>
            <Dialog
                open={confirmSupply}
                onClose={() => setConfirmSupply(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {loading && <Loader />}
                <DialogTitle id="alert-dialog-title">
                    Confirm supply of <b className="text-primary">{currentOrder?.quantity}kg</b> gas
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    You are confirming the requested gas quantity has been supplied?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className='text-danger' onClick={() => setConfirmSupply(false)}>No</Button>
                    <Button onClick={handleOrderSupply} autoFocus>
                        Yes, confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    </>
  );
}
